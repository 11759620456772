import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import endpoints from 'Root/endpoints';
import types from './mutation-types';
import { cloneDeep, delay, find, reject, uniq } from 'lodash';
import { getParams, addParams } from 'vanilla-js-url';
import { parseISO, format } from 'date-fns';

import user from './Modules/user.js';
import asset from './Modules/asset.js';
import workspace from './Modules/workspace.js';

Vue.use(Vuex);

const ASSET_INCLUDES = [
    'files',
    'brand',
    'property',
    'property.brand',
    'links',
    'thumbnails',
    'subject',
    'template_type',
    'asset_type',
];

const Store = new Vuex.Store({
    state: {
        isLoading: false,
        messages: {
            data: [],
            meta: {}
        },
        notifications: [],
        mainNavigation: {
            isVisible: true,
        },
        assets: {
            selected: [],
        },
        collection: {
            action: 'contact_sheet',
        },
        availableStatuses: [],
        search: {
            hasSearched: false,
            query: {
                asset_ids: [],
                asset_ids: [],
                asset_type_ids: [],
                asset_types: [],
                brand_ids: [],
                brand_only: false,
                brands: [],
                cities: [],
                collection_ids: [],
                collections: [],
                color_ids: [],
                colors: [],
                containers: [],
                created_by: null,
                datapoints: [],
                date_created_end: null,
                date_created_start: null,
                destination_controls: [],
                destinations: [],
                display: 'columns',
                division_ids: [],
                divisions: [],
                elevated_in_gallery: null,
                file_type_id: [],
                file_type_ids: [],
                file_types: [],
                from_mdam: null,
                groups: [],
                has_thumbnails: null,
                hidden_from_search: null,
                includes: ASSET_INCLUDES,
                is_offer: null,
                is_stock: null,
                keywords: [],
                last_modified_end: null,
                last_modified_start: null,
                limit: 96,
                limited_rights: null,
                link: null,
                mdam_ids: [],
                order_by: 'date_created_desc',
                page: 1,
                primary_property_image: null,
                properties: [],
                property_ids: [],
                property_only: false,
                has_rights_documents: false,
                recent_search_id: null,
                rights_managed: null,
                states_provinces: [],
                states: [],
                status_text: null,
                status: {name: 'Active',value: 'active',},
                stored_search_id: null,
                subject_ids: [],
                subjects: [],
                template_type_categories: [],
                template_type_category_ids: [],
                template_type_ids: [],
                template_types: [],
                travel_advisor_only: null,
                user_group_ids: [],
                from_dac: null,
            },
            recent_search_id: null,
            results: [],
            selected: [],
            meta: {
                total_pages: 1,
            },
            isLoading: false,
        },
    },
    modules: {
        user,
        asset,
        workspace,
    },
    actions: {
        getMessages({commit, state}, params) {
            commit(types.SET_IS_LOADING, true);

            axios.get('/ajax/system-messages', params)
                .then(response => {
                    commit(types.SET_SYSTEM_MESSAGES, response.data.messages.data);
                    commit(types.SET_SYSTEM_MESSAGES_META, response.data.meta);
                }).catch(error => {
                    //
                }).then(() => {
                    commit(types.SET_IS_LOADING, false);
                });
        },
        search({commit, state}) {
            commit(types.SET_SEARCH_IS_LOADING, true);

            let query = cloneDeep(state.search.query);

            query.property_ids = query.property_ids.concat(
                query.properties.map(property => {
                    return property.property_id.toString();
                })
            );

            query.property_ids = uniq(query.property_ids);

            delete query.properties;

            query.brand_ids = query.brand_ids.concat(
                query.brands.map(brand => {
                    return brand.brand_id.toString();
                })
            );

            query.brand_ids = uniq(query.brand_ids);

            delete query.brands;

            query.asset_type_ids = query.asset_type_ids.concat(
                query.asset_types.map(asset_type => {
                    return asset_type.asset_type_id.toString();
                })
            );

            query.asset_type_ids = uniq(query.asset_type_ids);

            delete query.asset_types;

            if (query.status) {
                query.status = query.status.value;
            }

            if (query.states) {
                delete query.states;
            }

            query.division_ids = query.division_ids.concat(
                query.divisions.map(division => {
                    return division.division_id.toString();
                })
            );

            query.division_ids = uniq(query.division_ids);

            delete query.divisions;

            query.user_group_ids = uniq(query.user_group_ids);
            delete query.groups;

            query.subject_ids = uniq(query.subject_ids);
            delete query.subjects;

            query.collection_ids = uniq(query.collection_ids);
            delete query.collections;

            if (!query.is_stock) {
                delete query.is_stock;
            }

            if (!query.from_mdam) {
                delete query.from_mdam;
            }

            if (!query.from_dac) {
                delete query.from_dac;
            }

            if (!query.is_offer) {
                delete query.is_offer;
            }

            if (!query.property_only) {
                delete query.property_only;
            }

            if (!query.has_rights_documents) {
                delete query.has_rights_documents;
            }

            if (!query.brand_only) {
                delete query.brand_only;
            }

            delete query.destinations;
            delete query.template_types;
            delete query.colors;
            delete query.file_types;
            delete query.template_type_categories;

            delete query.link;

            const searchQueryParams = getParams(window.location.search);

            if (searchQueryParams.hasOwnProperty('recent_search_id')) {
                query.recent_search_id = searchQueryParams.recent_search_id;
            } else {
                delete query.recent_search_id;
            }

            axios.get(endpoints.SEARCH, {
                params: query
            })
                .then(response => {

                    commit(types.SET_SEARCH_RESULTS, response.data.data.assets.data);
                    commit(types.SET_SEARCH_META, response.data.meta);

                    let query = response.data.data.search.search || {};

                    query.includes = ASSET_INCLUDES;
                    query.limit = response.data.meta.pagination.per_page;

                    commit(types.SET_SEARCH_QUERY, query);
                    commit(types.SET_SEARCH_HAS_SEARCHED, true);

                    history.pushState(query, '', query.link);

                    if (response.data.data.search.recent_search_id) {
                        commit(
                            types.SET_SEARCH_RECENT_SEARCH_ID,
                            response.data.data.search.recent_search_id
                        );
                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    commit(types.SET_SEARCH_IS_LOADING, false);
                });
        },
        prepareSearchQuery() {

        },
        populateSearchFromParams({commit, state, dispatch}) {
            if (
                window.location.pathname !== '/search' &&
                window.location.pathname !== '/search/'
            ) {
                return;
            }

            let params = getParams(window.location.search);
            let query = state.search.query;

            if (!Object.keys(params).length) {
                return;
            }

            for (var param in query) {
                if (!params[param]) {
                    continue;
                }

                switch (param) {
                    case 'status':
                        query.status = {
                            value: params.status
                        };
                        break;
                    case 'date_created_start':
                    case 'date_created_end':
                    case 'last_modified_start':
                    case 'last_modified_end':
                        let date = parseISO(params[param]);
                        query[param] = format(date, 'yyyy-MM-dd');
                        break;
                    default:
                        query[param] = params[param];
                }
            }

            commit(types.SET_SEARCH_QUERY, query);

            dispatch('search');
        },
        openMainNav({commit, state}) {
            commit(types.SET_MAIN_NAV_IS_OPEN, true);
        },
        closeMainNav({commit, state}) {
            commit(types.SET_MAIN_NAV_IS_OPEN, false);
        },
        addNotification({commit, state, dispatch}, notification) {
            let notifications = state.notifications;
            notifications.push(notification);

            if (notification.getTtl()) {
                delay(() => {
                    dispatch('clearNotification', notification);
                }, notification.getTtl() * 1000);
            }
            commit(types.SET_NOTIFICATIONS, notifications);
        },
        clearNotification({commit, state}, notification) {
            let notifications = reject(state.notifications, (existing) => {
                if (notification.getId() === existing.getId()) {
                    return true;
                }
            });

            commit(types.SET_NOTIFICATIONS, notifications);
        },
        setCollectionAction({commit}, action) {
            commit(types.SET_COLLECTION_ACTION, action);
        }
    },
    getters: {
        messages(state) {
            return state.messages.data;
        },
        display(state) {
            return state.search.query.display;
        },
    },
    mutations: {
        [types.SET_IS_LOADING] (state, isLoading) {
            state.isLoading = isLoading;
        },
        [types.SET_SYSTEM_MESSAGES] (state, messages) {
            state.messages.data = messages;
        },
        [types.SET_SYSTEM_MESSAGES_META] (state, meta) {
            state.messages.meta = meta;
        },
        [types.SET_MAIN_NAV_IS_OPEN] (state, isOpen) {
            state.mainNavigation.isOpen = isOpen;
        },
        [types.SET_NOTIFICATIONS] (state, notifications) {
            state.notifications = notifications;
        },
        [types.SET_COLLECTION_ACTION] (state, action) {
            state.collection.action = action;
        },

        // Search Stuff
        [types.SET_SEARCH_RESULTS](state, results) {
            state.search.results = results;
        },
        [types.SET_SEARCH_IS_LOADING] (state, isLoading) {
            state.search.isLoading = isLoading;
        },
        [types.SET_SEARCH_HAS_SEARCHED] (state, hasSearched) {
            state.search.hasSearched = hasSearched;
        },
        [types.SET_SEARCH_META] (state, meta) {
            state.search.meta = meta;
        },
        [types.SET_SEARCH_QUERY] (state, query) {
            state.search.query = query;
        },
        [types.SET_SEARCH_KEYWORDS] (state, keywords) {
            state.search.query.keywords = keywords;
        },
        [types.SET_SEARCH_BRANDS] (state, value) {
            state.search.query.brand_ids = value.map(brand => {
                return brand.brand_id;
            });
            state.search.query.brands = value;
        },
        [types.SET_SEARCH_PROPERTIES] (state, value) {
            state.search.query.property_ids = value.map(property => {
                return property.property_id;
            });

            state.search.query.properties = value;
        },
        [types.SET_SEARCH_ASSET_TYPES] (state, value) {
            state.search.query.asset_type_ids = value.map(asset_type => {
                return asset_type.asset_type_id;
            });

            state.search.query.asset_types = value;
        },
        [types.SET_SEARCH_ORDER_BY] (state, value) {
            state.search.query.order_by = value;
        },
        [types.SET_SEARCH_DIVISIONS] (state, value) {
            state.search.query.division_ids = value.map(division => {
                return division.division_id;
            });

            state.search.query.divisions = value;
        },
        [types.SET_SEARCH_STATES] (state, value) {
            state.search.query.states = value;

            state.search.query.states_provinces = value.map(selected => {
                return selected.state_id;
            });
        },
        [types.SET_SEARCH_STATES_PROVINCES] (state, value) {
            state.search.query.states_provinces = value;
        },
        [types.SET_SEARCH_CITIES] (state, value) {
            state.search.query.cities = value;
        },
        [types.SET_SEARCH_GROUPS] (state, value) {
            state.search.query.user_group_ids = value.map(group => {
                return group.user_group_id;
            });

            state.search.query.groups = value;
        },
        [types.SET_SEARCH_SUBJECTS] (state, value) {
            state.search.query.subject_ids = value.map(subject => {
                return subject.subject_id;
            });

            state.search.query.subjects = value;
        },
        [types.SET_SEARCH_COLLECTIONS] (state, value) {
            state.search.query.collection_ids = value.map(collection => {
                return collection.collection_id;
            });

            state.search.query.collections = value;
        },
        [types.SET_SEARCH_DATE_CREATED_START] (state, value) {
            state.search.query.date_created_start = value;
        },
        [types.SET_SEARCH_DATE_CREATED_END] (state, value) {
            state.search.query.date_created_end = value;
        },
        [types.SET_SEARCH_LAST_MODIFIED_START] (state, value) {
            state.search.query.last_modified_start = value;
        },
        [types.SET_SEARCH_LAST_MODIFIED_END] (state, value) {
            state.search.query.last_modified_end = value;
        },
        [types.SET_SEARCH_FROM_MDAM] (state, value) {
            state.search.query.from_mdam = value;
        },
        [types.SET_SEARCH_HIDDEN_FROM_SEARCH] (state, value) {
            state.search.query.hidden_from_search = value;
        },
        [types.SET_SEARCH_DESTINATIONS] (state, value) {
            state.search.query.destinations = value;
            state.search.query.destination_controls = value.map(destination => {
                return destination.id;
            });
        },
        [types.SET_SEARCH_DESTINATION_CONTROLS] (state, value) {
            state.search.query.destination_controls = value;
        },
        [types.SET_SEARCH_IS_STOCK] (state, value) {
            state.search.query.is_stock = value;
        },
        [types.SET_SEARCH_IS_OFFER] (state, value) {
            state.search.query.is_offer = value;
        },
        [types.SET_SEARCH_STATUS] (state, value) {
            state.search.query.status = value;
        },
        [types.SET_SEARCH_RECENT_SEARCH_ID] (state, value) {
            state.search.recent_search_id = value;
        },
        [types.SET_SEARCH_HAS_THUMBNAILS] (state, value) {
            state.search.query.has_thumbnails = value;
        },
        [types.SET_SEARCH_ASSETS] (state, value) {
            state.search.query.asset_ids = value;
        },
        [types.SET_SEARCH_LIMIT] (state, value) {
            state.search.query.limit = value;
        },
        [types.SET_SEARCH_PAGE] (state, value) {
            state.search.query.page = value;
        },
        [types.SET_SEARCH_BRAND_ONLY] (state, value) {
            state.search.query.brand_only = value;
        },
        [types.SET_SEARCH_PROPERTY_ONLY] (state, value) {
            state.search.query.property_only = value;
        },
        [types.SET_SEARCH_HAS_RIGHTS_DOCUMENTS] (state, value) {
            state.search.query.has_rights_documents = value;
        },
        [types.SET_AVAILABLE_STATUSES] (state, value) {
            state.availableStatuses = value;
        },
        [types.SET_SEARCH_TEMPLATE_TYPES](state, value) {
            state.search.query.template_types = value;

            state.search.query.template_type_ids = value.map(templateType => {
                return templateType.template_type_id;
            });
        },
        [types.SET_SEARCH_TEMPLATE_TYPE_CATEGORIES] (state, value) {
            state.search.query.template_type_categories = value;

            state.search.query.template_type_category_ids = value.map(category => {
                return category.template_type_category_id;
            });
        },
        [types.SET_SEARCH_PRIMARY_PROPERTY_IMAGE] (state, value) {
            state.search.query.primary_property_image = value;
        },
        [types.SET_SEARCH_ELEVATED_IN_GALLERY] (state, value) {
            state.search.query.elevated_in_gallery = value;
        },
        [types.SET_SEARCH_TRAVEL_ADVISOR_ONLY] (state, value) {
            state.search.query.travel_advisor_only = value;
        },
        [types.SET_SEARCH_LIMITED_RIGHTS] (state, value) {
            state.search.query.limited_rights = true;
        },
        [types.SET_SEARCH_RIGHTS_MANAGED] (state, value) {
            state.search.query.rights_managed = value;
        },
        [types.SET_SEARCH_CREATED_BY] (state, value) {
            state.search.query.created_by = value;
        },
        [types.SET_SEARCH_FILE_TYPES] (state, value) {
            state.search.query.file_types = value;

            state.search.query.file_type_ids = value.map(fileType => {
                return fileType.file_type_id;
            });
        },
        [types.SET_SEARCH_FILE_TYPES] (state, value) {
            state.search.query.file_types = value;

            state.search.query.file_type_ids = value.map(fileType => {
                return fileType.file_type_id;
            });
        },
        [types.SET_SEARCH_COLORS] (state, value) {
            state.search.query.colors = value;

            state.search.query.color_ids = value.map(color => {
                return color.color_id;
            });
        },
        [types.SET_SEARCH_DATAPOINTS] (state, value) {
            state.search.query.datapoints = value;
        },
        [types.SET_SEARCH_CONTAINERS] (state, value) {
            state.search.query.containers = value;
        },
        [types.SET_SEARCH_MDAM_IDS] (state, value) {
            state.search.query.mdam_ids = value;
        },
        [types.SET_SEARCH_FROM_DAC] (state, value) {
            state.search.query.from_dac = value;
        },
        [types.SET_SEARCH_DISPLAY_FORMAT] (state, value) {
            if (state.search.query.display != value) {

                const regex = new RegExp(`(\\?|&)display=${state.search.query.display}(?=&|$)`, 'i');  

                // Trap for null query.link on mounted, before search has finished loading
                if (state.search.query.link) {
                    const link = state.search.query.link.replace(regex, `$1display=${value}`);
                    state.search.query.link = link;
                    history.pushState(null, '', link)
                }                

                state.search.query.display = value;
            }            
        },
    }
});

export default Store;
