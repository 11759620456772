<template>
<div class="assetCard">
    <div class="assetCard__body">
        <div class="assetCard__status">
            <div class="checkbox -assetCard">
                <input
                    v-if="isSelectable"
                    :id="`select-${asset.asset_id}`"
                    v-model="model"
                    :name="`selected-${asset.asset_id}`"
                    type="checkbox"
                    class="checkbox__input"
                    @click="updateSelected($event)"
                >

                <label
                    class="checkbox__label"
                    :for="`select-${asset.asset_id}`"
                    v-text="selectLabel"
                />

                <span
                    :class="getStatusClasses()"
                    v-text="statusText"
                />
            </div>
        </div>

        <div class="assetCard__thumbnail">
            <asset-thumbnail
                :asset="asset"
            />
        </div>

        <div class="assetCard__fileName">
            <span class="assetCard__fileID" v-html="asset.filename" />
            <span v-if="isAdmin && asset.links.edit">
                | <a
                    class="assetCard__edit"
                    :href="asset.links.edit"
                >Edit Asset</a>
            </span>
        </div>

        <div class="assetCard__content">
            <div class="assetCard__heading">
                <span
                    v-if="asset.association === 'property'"
                    class="assetCard__eyebrow"
                    v-html="asset.property.brand.name"
                />
                <span
                    v-else
                    class="assetCard__eyebrow"
                    v-html="asset.brand.name"
                />

                <span
                    v-if="asset.association === 'property'"
                    class="assetCard__title"
                    v-html="asset.property.name"
                />

                <span
                    v-if="asset.caption"
                    class="assetCard__mustache"
                >
                    {{ asset.caption }}
                </span>
            </div>

            <div class="assetCard__footer">
                <dl class="text-slate-light text-sm">
                    <div class="assetCardFull__date" v-if="asset.subject">
                        <dt>Subject</dt>
                        <dd>{{ asset.subject.name }}</dd>
                    </div>
                    <div class="assetCardFull__date" v-if="assetType === 'Template'">
                        <dt>Type</dt>
                        <dd>{{ templateType }}</dd>
                    </div>
                    <div class="assetCardFull__date" v-if="assetType == 'Logo'">
                        <dt>Color</dt>
                        <dd>{{ color }}</dd>
                    </div>
                    <div class="assetCardFull__date" v-if="asset.deactivation_reason">
                        <dt>Reason Deactivated</dt>
                        <dd>{{ asset.deactivation_reason }}</dd>
                    </div>
                    <div class="assetCard__date">
                        <dt>Date Created</dt>
                        <dd>{{ formattedDateCreated }}</dd>
                    </div>
                    <div class="assetCard__date">
                        <dt>Date Updated</dt>
                        <dd>{{ formattedDateUpdated }}</dd>
                    </div>
                    <div
                        v-if="likeable.show"
                        class="assetCard__switch"
                    >
                        <dt>Likes</dt>
                        <dd>
                            <like-toggle
                                :id="`like_toggle_${asset.asset_id}`"
                                :asset-id="asset.asset_id"
                                :count="likeable.count"
                                :liked="likeable.liked"
                                @input="toggleLiked()"
                            />
                        </dd>
                    </div>
                    <div
                        v-if="isAdmin"
                        class="assetCard__switch"
                    >
                        <dt>Visible to API</dt>
                        <dd>
                            <toggle-switch
                                :id="`visible_to_api_${asset.asset_id}`"
                                v-model="visibleToApi"
                                name="visible_to_api"
                                :input-value="true"
                                :unchecked-value="false"
                                @input="toggleApiVisibility()"
                                type="assetCard"
                            />
                        </dd>
                    </div>
                </dl>
                <slot name="footer-extras" />
            </div>
        </div>
    </div>

    <div class="assetCard__actions">
        <div v-if="showViewLink" class="assetCard__links -border">
            <a
                :href="asset.links.view"
                class="assetCard__link"
            >
                View
                </a><span class="assetCard__arrow"></span>
            </div>
            <asset-downloads
                :asset="asset"
                :extra-drawer-classes="['py-4']"
                icon-style="light"
            />
    </div>
</div>
</template>

<script>
import Drawer from '@/Drawer';
import AssetThumbnail from '@/AssetThumbnail';
import AssetDownloads from '@/AssetDetails/AssetDownloads';
import AssetMixins from '@/Mixins/AssetMixins';
import LikeToggle from '@/LikeToggle';
import ToggleSwitch from '@/ToggleSwitch';

export default {
    components: {
        Drawer,
        AssetThumbnail,
        AssetDownloads,
        LikeToggle,
        ToggleSwitch
    },
    mixins: [
        AssetMixins,
    ],
    props: {
        asset: {
            required: true,
            type: Object,
        },
        selected: {
            required: false,
            type: Boolean,
        },
        isSelectable: {
            required: false,
            type: Boolean,
            default: true,
        },
        selectLabel: {
            required: false,
            type: String,
            default: '',
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
        showViewLink: {
            required: false,
            type: Boolean,
            default: true,
        },
        likeable: {
            required: false,
            type: Object,
            default: function() {
                return {
                    show:false, 
                    count:0, 
                    liked:false
                }
            },
        },
    },
    data() {
        return {
            isSelected: false,
        };
    },
    computed: {
        liked: {
            get() {
                return this.selected;
            },
            set(isSelected) {
                this.isSelected = isSelected;
            }
        },
        model: {
            get() {
                return this.selected;
            },
            set(isSelected) {
                this.isSelected = isSelected;
            }
        },
    },
    methods: {
        toggleLiked(event) {
            let val = !this.likeable.liked;
            this.$emit('liked', val);
        },
        updateSelected(event) {
            this.$emit('input', event.target.checked);
        },
    }
};
</script>
