export default {
    data: () => ({
        viewOptions: false,
        listeners: false,
    }),
    methods: {
        toggleOptions() {
            this.viewOptions = ! this.viewOptions;

            if (this.viewOptions) {
                this.addListeners();
            } else {
                this.removeListeners();
            }
        },

        openOptions() {
            if (this.viewOptions) {
                return;
            }

            this.viewOptions = true;
            this.addListeners();
        },

        closeOptions(e) {
            if (! this.viewOptions) {
                return;
            }

            if (e && e.target.closest(`div[id="searchInput--${this.id}"]`)) {
                return;
            }

            this.viewOptions = false;
            this.removeListeners();
        },

        bodyListener(e) {
            if (e && e.key === 'Escape') {
                this.closeOptions(e);
            }
        },

        addListeners() {
            if (! this.listeners) {
                document.body.addEventListener('click', this.closeOptions, true);
                document.body.addEventListener('keyup', this.bodyListener, true);

                this.listeners = true;
            }
        },

        removeListeners() {
            if (this.listeners) {
                document.body.removeEventListener('click', this.closeOptions);
                document.body.removeEventListener('keyup', this.bodyListener);

                this.listeners = false;
            }
        },
    },
};