<template>
<a
    :href="asset.links.view"
    class="relatedAsset"
    :style="`background-image: url('${asset.asset_thumbnail_url}')`"
>
    <div class="relatedAsset--details">
        <span class="relatedAsset--type">{{ assetType }}</span>
        <p class="relatedAsset--caption">
            {{ caption }}
        </p>
    </div>
</a>
</template>

<script>
import AssetMixins from '@/Mixins/AssetMixins';
import AssetThumbnail from '@/AssetThumbnail';

export default {
    mixins: [
        AssetMixins,
        AssetThumbnail,
    ],
    props: {},
};
</script>

<style>

</style>
