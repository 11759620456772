<template>
<section class="workspaces__cardWrapper -noPaddingTop">
    <div class="workspaces cards">
        <workspace-card
            v-for="workspace in workspaces"
            :key="workspace.workspace_id"
            :workspace="workspace"
        />
        <loading
            v-if="isLoading"
            :is-loading="isLoading"
        />
    </div>
</section>
</template>

<script>
import { mapState } from 'vuex';
import WorkspaceCard from '@/WorkspaceCard';
import Loading from 'Components/Loading';

export default {
    components: {
        WorkspaceCard,
        Loading
    },
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        ...mapState({
            workspaces: state => state.user.workspaces
        }),
    },
    mounted() {
        this.fetchWorkspaces();
    },
    methods: {
        fetchWorkspaces() {
            if (this.workspaces.length > 0) {
                this.isLoading = false;
                return;
            }

            this.isLoading = true;

            this.$store.dispatch('user/getWorkspaces').finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>

<style></style>
