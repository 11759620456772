<template>
<div>
    <div class="select-all-button">
        <a :class="['button -ilb', selectedToggle ? '-delete' : '-add']" @click="selectAll">{{ selectedToggle ? 'Deselect' : 'Select' }} All Assets</a>
    </div>
    <div class="workspace__assets flex flex-wrap">
        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <div class="workspace__actions">
                <div v-if="selected.length" class="workspace__move">
                    <move-to-workspace
                        class="assetDetails--actionLink relative"
                        :workspace-id="workspaceId"
                        :workspaces="$store.state.user.workspaces"
                        :asset="selected"
                        @moved="fetchAssets"
                    />
                </div>
                <form
                    v-if="canBulkEdit"
                    action="/sitemanager/assets/advanced_search"
                    method="GET"
                    class="w-full mr-4 text-right"
                >
                    <input type="hidden" name="selected_action" value="Edit Selected Assets"> 
                    <input
                        v-for="asset in selected"
                        :key="asset.asset_id"
                        type="hidden"
                        name="selected_assets[]"
                        :value="asset.asset_id"
                    >
                    <input type="hidden" name="action" value="edit"> 
                    <input type="hidden" name="recent_search_id" value=""> 
                    <input type="hidden" name="redirect_url" :value="redirectUrl"> 

                    <button class="button">
                        Edit {{ selected.length }} in Sitemanager
                    </button>
                </form>
                <form
                    v-if="selected.length"
                    :action="removalAction"
                    method="POST"
                    class="w-full text-right"
                > 
                    <input
                        v-for="asset in selected"
                        :key="asset.asset_id"
                        type="hidden"
                        name="asset_ids[]"
                        :value="asset.asset_id"
                    >

                    <button class="button">
                        Remove {{ selected.length }} from Workspace
                    </button>
                </form>
            </div>
        </transition>

        <div class="workspace__filters assetSearch__panel"> 
            <fieldset class="assetSearch__filters">
                <div class="assetSearch__filter">
                    <label class="assetSearch__label" for="asset-type">ASSET TYPE</label>
                    <filter-select 
                        v-model="filters.assetTypes"
                        id="asset_type_filter"
                        :options="assetTypes"
                        placeholder="Filter by asset type"
                        css-class="assetSearch__placeholder"
                        v-bind:multiple="true"
                    />
                </div>
                <div class="assetSearch__filter">
                    <label class="assetSearch__label" for="property">PROPERTY</label>
                    <filter-select 
                        v-model="filters.properties"
                        id="properties_filter"
                        :options="properties"
                        placeholder="Filter by property"
                        css-class="assetSearch__placeholder"
                        v-bind:multiple="true"
                    />
                </div>
                <div class="assetSearch__filter">
                    <label class="assetSearch__label" for="subject">SUBJECT</label>
                    <filter-select 
                        v-model="filters.subjects"
                        id="subjects_filter"
                        :options="subjects"
                        value-key="subject_id"
                        display-key="name"
                        placeholder="Filter by subject"
                        css-class="assetSearch__placeholder"
                        v-bind:multiple="true"
                    />
                </div>
                <div class="assetSearch__filter">
                    <label class="assetSearch__label" for="status">STATUS</label>
                    <filter-select 
                        v-model="filters.statuses"
                        id="statuses_filter"
                        :options="statuses"
                        placeholder="Filter by status"
                        css-class="assetSearch__placeholder"
                        v-bind:multiple="true"
                    />
                </div>
            </fieldset>
        </div>

        <div v-if="!loading && assets.length === 0">
        <p><strong>There are currently no assets in this workspace.</strong></p>
        <p>To add assets:</p>
        <ol>
            <li>Use the search box at the top of the page to find the assets you wish to add.</li>
            <li>When you have found the assets, check the box "Add to Workspace."</li>
            <li>Once you have the assets checked, select the workspace you wish to add them to at the bottom of the page.</li>
            <li>Hit "Go" and the assets will be saved to the workspace you specified.</li>
        </ol>
        </div>
        <div
        v-else
        v-for="asset in filteredAssets"
        :key="asset.asset_id"
        class="workspace__asset"
        >
            <asset-card
                class="mr-4"
                :asset="asset"
                select-label=""
                :selected="isSelected(asset)"
                :is-selectable="!anonymous"
                :show-view-link="!anonymous"
                :likeable="likeable(asset)"
                @liked="toggleLiked($event, asset.asset_id)"
                @input="updateSelected($event, asset)"
            />
        </div>

        <loading
        v-if="loading"
        :is-loading="loading"
        ></loading>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import AssetCard from '@/AssetCard';
import MoveToWorkspace from '@/MoveToWorkspace'
import FilterSelect from 'Components/FilterSelect';
import Loading from 'Components/Loading';
import { reject, find, differenceWith, isEqual } from 'lodash';

export default {
    components: {
        AssetCard,
        MoveToWorkspace,
        FilterSelect,
        Loading,
    },
    props: {
        workspaceId: {
            required: true,
            type: Number,
        },
        removalAction: {
            required: true,
            type: String,
        },
        redirectUrl: {
            required: true,
            type: String,
        },
        anonymous: {
            required: false,
            default: false,
            type: Boolean,
        },
        isAdmin: {
            required: false,
            default: 0,
            type: Number,
        },
        uid: {
            required: true,
            type: Number,
        }
    },
    data: () => ({
        assets: [],
        likes: [],
        loading: false,
        selectedToggle: false,
        subjectData: [],
        filters: {
            assetTypes: [],
            properties: [],
            subjects: [],
            statuses: [],
        }
    }),
    async mounted() {
        await this.fetchAssets();
        await this.fetchLikes();
        await this.fetchSubjectData();
        this.$store.dispatch('user/getWorkspaces');
    },

    computed: {
        selected: {
            get() {
                return this.$store.state.workspace.workspaceSelectedAssets || [];
            },
            set(assets) {
                this.$store.dispatch('workspace/setSelectedAssets', assets)
            },
        },
        countFilters() {
            let f = 0;

            if (this.filters.assetTypes.length) {
                f += 1;
            }
            if (this.filters.properties.length) {
                f += 1;
            }
            if (this.filters.subjects.length) {
                f += 1;
            }
            if (this.filters.statuses.length) {
                f += 1;
            }

            return f;
        },
        filteredAssets() {
            let f = this.countFilters;

            if (!f) {
                return this.assets;
            }
            
            let m = 0;
            let filtered = [];

            let filters = this.filters;

            this.assets.forEach(asset => {
                m = 0;
                if (filters.assetTypes.length && asset.asset_type) {
                    if (this.isMatch(filters.assetTypes, asset.asset_type.name)){
                        m += 1;
                    }
                }
                if (filters.properties.length && asset.property) {
                    if (this.isMatch(filters.properties, asset.property.name)){
                        m += 1;
                    }
                }
                if (filters.subjects.length) {
                    if (this.isMatch(filters.subjects, asset.subject_id)){
                         m += 1;
                    }
                }
                if (filters.statuses.length) {
                    if (this.isMatch(filters.statuses, asset.status)){
                         m += 1;
                    }
                }

                if (f == m) {
                    filtered.push(asset);
                }
            });

            return filtered;
        },
        canBulkEdit() {
            if (!this.isAdmin) {
                return false;
            }
            if (!this.selected.length) {
                return false;
            }
            return true;
        },
        assetTypes() {
            let values = [];

            if (this.assets.length > 0) {
                this.assets.forEach(a => {
                    if (a.asset_type && values.indexOf(a.asset_type.name) == -1) {
                        values.push(a.asset_type.name);
                    }
                });
            }

            return values;
        },
        properties() {
            let values = [];

            if (this.assets.length > 0) {
                this.assets.forEach(a => {
                    if (a.property && values.indexOf(a.property.name) == -1) {
                        values.push(a.property.name);
                    }
                });
            }

            return values;
        },
        subjects() {
            let temp = [];

            if (this.assets.length > 0) {
                this.assets.forEach(a => {
                    if (a.subject_id && temp.indexOf(a.subject_id) == -1) {
                        temp.push(a.subject_id);
                    }
                });
            }

            if (temp.length == 0) {
                return [];
            }

            let values = [];

            this.subjectData.forEach(s => {
                if (temp.indexOf(s.subject_id) !== -1) {
                    values.push(s);
                }
            });

            return values;
        },
        statuses() {
            let values = [];
            
            if (this.assets.length > 0) {
                this.assets.forEach(a => {
                    if (a.status && values.indexOf(a.status) == -1) {
                        values.push(a.status);
                    }
                });
            }

            return values;
        },
    },
    methods: {
        async fetchAssets() {
          this.loading = true;
          await axios.get(`/api/workspaces/${this.workspaceId}/assets`)
            .then((response) => {
                this.assets = response.data.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.loading = false));
        },
        async fetchLikes() {
          await axios.get(`/api/workspace/likes/${this.workspaceId}`)
            .then((response) => {
                this.likes = response.data.data;

                console.log(this.likes);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async fetchSubjectData() {
          await axios.get(`/api/list-subjects`)
            .then((response) => {
                this.subjectData = response.data.subjects;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        toggleLiked(isLiked, assetId) {
            let url = `/api/workspace/likes/${this.workspaceId}/${assetId}/add`;

            if (!isLiked) {
                url = `/api/workspace/likes/${this.workspaceId}/${assetId}/remove`;
            }
            console.log(assetId);
            console.log(url);
            axios.get(url)
                .then(() => {
                    this.fetchLikes();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateSelected(isChecked, asset) {
            if (isChecked) {
                let selected = this.selected.map((s) => s);
                selected.push(asset);
                this.selected = selected;
            } else {
                this.selected = reject(this.selected, asset);
            }
        },
        isSelected(asset) {
            return find(this.selected, asset) ? true : false;
        },
        likeable(asset) {
            let params = {
                show: true,
                count: 0,
                liked: false
            }

            if (typeof this.likes[asset.asset_id] !== 'undefined') {
                params.count = this.likes[asset.asset_id].length;
                params.liked = this.likes[asset.asset_id].indexOf(this.uid.toString()) !== -1;
            }
            return params;
        },
        selectAll() {
            this.selectedToggle = !this.selectedToggle;

            let asset = null;
            let i = 0;
            for (i = 0; i < this.filteredAssets.length; i++) {
                asset = this.filteredAssets[i];

                if (this.selectedToggle) {
                    if (!this.isSelected(asset)) {
                        this.updateSelected(true, asset);
                    }
                } else {
                    if (this.isSelected(asset)) {
                        this.updateSelected(false, asset);
                    }
                }
            }
        },
        isMatch(value, match) {
            if (Array.isArray(value)) {
                return value.indexOf(match) !== -1;
            } else {
                return value == match;
            }
        }
    }
};
</script>