<script>
export default {
    props: {
        visible: {
            default: false,
            required: false,
            type: Boolean
        },
        contentClasses: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        transparent: {
            type: Boolean,
            default: false,
            required: false,
        },
        overflowScroll: {
            type: Boolean,
            default: true,
            required: false,
        }
    },
    data() {
        return {
            body: document.querySelector('body'),
            classes: ['modal', '--is-open']
        };
    },
    computed: {
        isVisible() {
            if (this.visible) {
                this.addBodyListener();
                this.addBodyClasses();
                return true;
            } else {
                this.removeBodyListener();
                this.removeBodyClasses();
                return false;
            }
        }
    },
    mounted() {
        //
    },
    methods: {
        addBodyListener() {
            this.body.addEventListener('keyup', this.bodyListener);
        },
        removeBodyListener() {
            this.body.removeEventListener('keyup', this.bodyListener);
        },
        bodyListener(event) {
            if (event.key === 'Escape') {
                this.hideModal();
            }
        },
        addBodyClasses() {
            this.body.classList.add(...this.classes);
        },
        removeBodyClasses() {
            this.body.classList.remove(...this.classes);
        },
        showModal() {
            this.$emit('update:visible', true);
        },
        hideModal(event) {
            this.$emit('close', true);
            this.$emit('update:visible', false);
        },
        getContentClasses() {
            let classes = [
                'modal__content',
            ];

            if (this.transparent) {
                classes.push('--transparent');
            }

            if (this.overflowScroll) {
                classes.push('--overflow-scroll');
            }

            return classes.concat(this.contentClasses).join(' ');
        }
    }
};
</script>

<template>
<transition
    enter-active-class="transition duration-100 ease-out"
    enter-class="transform  opacity-0"
    enter-to-class="transform opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-class="transform opacity-100"
    leave-to-class="transform  opacity-0"
>
    <div
        v-if="isVisible"
        :class="[
            'modal__background',
        ]"
        @keyup.esc="hideModal"
        @click.prevent="hideModal"
    >
        <button
            class="modal__close button"
            @click.prevent="hideModal"
        >
            <i class="far fa-2x"></i>
        </button>
        <div :class="getContentClasses()">
            <slot />
        </div>
    </div>
</transition>
</template>

<style lang="scss">
.modal {
  &.--is-open {
    height: 100vh;
    overflow: hidden;
  }

  &__background {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  &__close {
    border: 3px solid #003A5D;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index:102;

    &::before {
        content: "\f00d";
        color: #003A5D;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        font-size: 2rem;
    }

    &:hover {
        background-color: #D4D5D8;
    }
    @media only screen and (min-width: 600px){
        right: 23%;
        top: 27%;
    }
  }

  &__content {
    background: #fff;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    max-height: 50%;
    max-width: 90%;
    padding: 2rem;
    z-index: 101;

    @media only screen and (min-width: 600px){
        top: 55%;
    }

    img {
        object-fit: contain;
        object-position: center;
        min-height: 200px;
        width: 100%;
    }

    &.--transparent {
        background-color: transparent;
    }

    &.--overflow-scroll {
        overflow-y: auto;
    }
    &.text-center {
        text-align: center;
    }

  }
}
</style>
