<script>
export default {
    props: {
        isLoading: {
            default: Boolean,
            required: true
        }
    },
    data() {
        return {}
    }
}
</script>

<template>
<div :class="['loading', {'-is-loading': isLoading}]">
    <i class="loading__icon fa fa-spin fa-spinner"></i>
</div>
</template>

<style lang="scss">
@import '../../../styles/sitemanager/colors';

.loading {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, .25);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &.-is-loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        color: $primary;
        font-family: 'Font Awesome 5 Pro';
        font-size: 3.8rem;
        font-weight: 400;
    }
}
</style>
