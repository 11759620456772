import "@/assets/dropdown"
import "@/assets/tabs";
import AssetDetails from '@/AssetDetails';
import AssetListing from '@/AssetListing';
import AssetSearch from '@/AssetSearch';
import AssetMetadata from '@/AssetMetadata';
import AssetSearchFilters from '@/AssetSearchFilters';
import AssetSearchSelectedFilters from '@/AssetSearchSelectedFilters';
import BpAccordion from '@/BpAccordion';
import CollapsingMenu from '@/CollapsingMenu';
import Drawer from '@/Drawer';
import DownloadWorkspaceAssets from '@/Workspace/DownloadWorkspaceAssets';
import LinkNav from '@/LinkNav';
import Loading from '@/Loading';
import Notifications from '@/Notifications';
import RelatedAssets from '@/RelatedAssets';
import SearchInput from '@/SearchInput';
import store from 'Stores/Frontend';
import SystemMessages from '@/SystemMessages';
import Tabs from '@/Tabs';
import UserStuff from '@/UserStuff'
import UserWorkspacesList from '@/UserWorkspacesList';
import WorkspaceAssets from '@/WorkspaceAssets';
import WorkspaceAddTo from '@/Workspace/AddTo';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import Vuex from 'vuex';
import VueLazyLoad from 'vue-lazyload';
import VueRouter from 'vue-router';
import AssetCollection from '@/AssetCollection';

import router from 'Root/Apps/Generic/router.js';

Vue.use(VueLazyLoad);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VeeValidate);

new Vue({
    el: document.querySelector('.mvw'),
    store,
    router,
    components: {
        AssetCollection,
        AssetDetails,
        AssetListing,
        AssetMetadata,
        AssetSearch,
        AssetSearchFilters,
        AssetSearchSelectedFilters,
        BpAccordion,
        CollapsingMenu,
        Drawer,
        DownloadWorkspaceAssets,
        LinkNav,
        Loading,
        Notifications,
        RelatedAssets,
        SearchInput,
        SystemMessages,
        Tabs,
        UserStuff,
        UserWorkspacesList,
        WorkspaceAddTo,
        WorkspaceAssets,
    },
    data: {},
    computed: {
        searchLoading() {
            return this.$store.state.search.isLoading;
        },
    },
    store,
    mounted() {
        store.dispatch('populateSearchFromParams');
    },
    methods: {
        goToPage(page) {
            window.location.href = page;
        }
    }
});

// Handle tabs gracefully as per https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2å
function handleFirstTab(e) {

    if (e.keyCode === 9) {
        // console.log('Ive been pushed')
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}

function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

window.addEventListener('keydown', handleFirstTab);
