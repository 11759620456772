<template>
<section>
    <div class="searchResults__selected">
        <h3 class="searchResults__selectedHeading">Current Selected Filters</h3>
            <a
                href="/search/"
                class="searchResults__clearLink"
            >
                Clear All
            </a>
        <p v-if="recentSearchId" class="searchResults__linkRight">
            <a
                href="#"
                class="searchResults__saveLink"
                @click.prevent="showSaveSearchModal = true"
            >
                Save Search
                <span class="add searchResults__add"></span>
            </a>
        </p>
    </div>

    <modal
        :visible.sync="showSaveSearchModal"
        :transparent="false"
        :overflow-scroll="true"
    >
        <save-search
            v-if="showSaveSearchModal"
            :recent-search-id="recentSearchId"
            :query="query"
            @saved="showSaveSearchModal = false"
        ></save-search>
    </modal>

    <div class="searchResults__pills">
        <button
            v-for="brand in query.brands"
            :key="brand.brand_id"
            class="pill"
            @click="removeArrayFilter('brands', brand)"
        >
            <span
                class="mr-1"
                v-text="brand.name"
            />
            <span class="exit"></span>
        </button> <!-- -->

        <button
            v-for="property in query.properties"
            :key="property.marsha_code"
            class="pill"
            @click="removeArrayFilter('properties', property)"
        >
            <span
                class="mr-1"
                v-text="property.marsha_code"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.status.value === 'all'"
            class="pill"
        >
            <span
                class="mr-1"
                v-text="query.status.name"
            />
        </button>
        <button
            v-else
            class="pill"
            @click="removeStatus()"
        >
            <span
                class="mr-1"
                v-text="query.status.name"
            />
            <span class="exit"></span>
        </button>

        <template v-if="query.asset_types.length">
            <button
                v-for="assetType in query.asset_types"
                :key="assetType.asset_type_id"
                class="pill"
                @click="removeArrayFilter('asset_types', assetType)"
            >
                <span
                    class="mr-1"
                    v-text="assetType.name"
                />
                <span
                    v-if="assetType.asset_type_id !== 0"
                    class="exit"
                ></span>
            </button>
        </template>
        <template v-else>
            <button class="pill">
                <span class="mr-1">All Asset Types</span>
                <span class="exit"></span>
            </button>
        </template>

        <button
            v-for="(keyword, i) in query.keywords"
            :key="i"
            class="pill"
            @click="removeArrayFilter('keywords', keyword, false)"
        >
            <span
                class="mr-1"
                v-text="'Keyword: ' + keyword"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="division in query.divisions"
            :key="division.division_id"
            class="pill"
            @click="removeArrayFilter('divisions', division)"
        >
            <span
                class="mr-1"
                v-text="'Division: ' + division.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="state in query.states"
            :key="state.state_id"
            class="pill"
            @click="removeArrayFilter('states', state)"
        >
            <span
                class="mr-1"
                v-text="'State: ' + state.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="(city, i) in query.cities"
            :key="i"
            class="pill"
            @click="removeArrayFilter('cities', city, false)"
        >
            <span
                class="mr-1"
                v-text="'City: ' + city"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="group in query.groups"
            :key="group.user_group_id"
            class="pill"
            @click="removeArrayFilter('groups', group)"
        >
            <span
                class="mr-1"
                v-text="'Group: ' + group.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="subject in query.subjects"
            :key="subject.subject_id"
            class="pill"

            @click="removeArrayFilter('subjects', subject)"
        >
            <span
                class="mr-1"
                v-text="'Subject: ' + subject.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="collection in query.collections"
            :key="collection.collection_id"
            class="pill"

            @click="removeArrayFilter('collections', collection)"
        >
            <span
                class="mr-1"
                v-text="'Collection: ' + collection.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.date_created_start"
            class="pill"

            @click="removeStringFilter('date_created_start')"
        >
            <span
                class="mr-1"
                v-text="`Date Created Start: ${query.date_created_start}`"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.date_created_end"
            class="pill"

            @click="removeStringFilter('date_created_end')"
        >
            <span
                class="mr-1"
                v-text="`Date Created End: ${query.date_created_end}`"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.last_modified_start"
            class="pill"

            @click="removeStringFilter('last_modified_start')"
        >
            <span
                class="mr-1"
                v-text="`Last Updated Start: ${query.last_modified_start}`"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.last_modified_end"
            class="pill"

            @click="removeStringFilter('last_modified_end')"
        >
            <span
                class="mr-1"
                v-text="`Last Modified End: ${query.last_modified_end}`"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.from_mdam"
            class="pill"

            @click="removeBoolFilter('from_mdam')"
        >
            <span
                class="mr-1"
                v-text="'Imported from MDAM'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="destination in query.destinations"
            :key="destination.destination_id"
            class="pill"

            @click="removeArrayFilter('destinations', destination)"
        >
            <span
                class="mr-1"
                v-text="'Destination: ' + destination.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="type in query.template_types"
            :key="type.template_type_id"
            class="pill"

            @click="removeArrayFilter('template_types', type)"
        >
            <span
                class="mr-1"
                v-text="'Template Type: ' + type.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="category in query.template_type_categories"
            :key="category.template_type_category_id"
            class="pill"

            @click="removeArrayFilter('template_categories', category)"
        >
            <span
                class="mr-1"
                v-text="'Template Category: ' + category.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="color in query.colors"
            :key="color.color_id"
            class="pill"

            @click="removeArrayFilter('colors', color)"
        >
            <span
                class="mr-1"
                v-text="'Color: ' + color.name"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.is_stock"
            class="pill"

            @click="removeBoolFilter('is_stock')"
        >
            <span
                class="mr-1"
                v-text="'Is Stock'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.hidden_from_search"
            class="pill"

            @click="removeBoolFilter('hidden_from_search')"
        >
            <span
                class="mr-1"
                v-text="'Hidden From Search'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.property_only"
            class="pill"

            @click="removeBoolFilter('property_only')"
        >
            <span
                class="mr-1"
                v-text="'Property Only'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.brand_only"
            class="pill"

            @click="removeBoolFilter('brand_only')"
        >
            <span
                class="mr-1"
                v-text="'Brand Only'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.has_rights_documents"
            class="pill"

            @click="removeBoolFilter('has_rights_documents')"
        >
            <span
                class="mr-1"
                v-text="'Has Rights Documents'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-if="query.from_dac"
            class="pill"

            @click="removeBoolFilter('from_dac')"
        >
            <span
                class="mr-1"
                v-text="'Imported from DAC'"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="(container, i) in query.containers"
            :key="i"
            class="pill"

            @click="removeArrayFilter('containers', container, false)"
        >
            <span
                class="mr-1"
                v-text="'Container: ' + container"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="(datapoint, i) in query.datapoints"
            :key="i"
            class="pill"

            @click="removeArrayFilter('datapoints', datapoint, false)"
        >
            <span
                class="mr-1"
                v-text="'Datapoint: ' + datapoint"
            />
            <span class="exit"></span>
        </button>

        <button
            v-for="(mdam_id, i) in query.mdam_ids"
            :key="i"
            class="pill"

            @click="removeArrayFilter('mdam_ids', mdam_id, false)"
        >
            <span
                class="mr-1"
                v-text="'MDAM: ' + mdam_id"
            />
            <span class="exit"></span>
        </button>
    </div>
</section>
</template>

<script>
import mutations from 'Stores/Frontend/mutation-types';
import { ADD_STORED_SEARCH } from 'Root/endpoints';
import { reject, pull } from 'lodash';
import Modal from 'Components/Modal';
import SaveSearch from 'Components/SaveSearch';

const MUTATIONS_MAP = {
    // object array props
    'asset_types': mutations.SET_SEARCH_ASSET_TYPES,
    'brands': mutations.SET_SEARCH_BRANDS,
    'properties': mutations.SET_SEARCH_PROPERTIES,
    'divisions': mutations.SET_SEARCH_DIVISIONS,
    'states': mutations.SET_SEARCH_STATES,
    'subjects': mutations.SET_SEARCH_SUBJECTS,
    'collections': mutations.SET_SEARCH_COLLECTIONS,
    'destinations': mutations.SET_SEARCH_DESTINATION_CONTROLS,
    'status': mutations.SET_SEARCH_STATUS,
    'template_categories': mutations.SET_SEARCH_TEMPLATE_TYPE_CATEGORIES,
    'template_types': mutations.SET_SEARCH_TEMPLATE_TYPES,
    'groups': mutations.SET_SEARCH_GROUPS,
    'mdam_ids': mutations.SET_SEARCH_MDAM_IDS,
    'colors': mutations.SET_SEARCH_COLORS,

    // value array props
    'keywords': mutations.SET_SEARCH_KEYWORDS,
    'cities': mutations.SET_SEARCH_CITIES,
    'containers': mutations.SET_SEARCH_CONTAINERS,
    'datapoints': mutations.SET_SEARCH_DATAPOINTS,

    // string props
    'date_created_start': mutations.SET_SEARCH_DATE_CREATED_START,
    'date_created_end': mutations.SET_SEARCH_DATE_CREATED_END,
    'last_modified_start': mutations.SET_SEARCH_LAST_MODIFIED_START,
    'last_modified_end': mutations.SET_SEARCH_LAST_MODIFIED_END,

    // boolean props
    'hidden_from_search': mutations.SET_SEARCH_HIDDEN_FROM_SEARCH,
    'is_stock': mutations.SET_SEARCH_IS_STOCK,
    'from_mdam': mutations.SET_SEARCH_FROM_MDAM,
    'property_only': mutations.SET_SEARCH_PROPERTY_ONLY,
    'brand_only': mutations.SET_SEARCH_BRAND_ONLY,
    'has_rights_documents': mutations.SET_SEARCH_HAS_RIGHTS_DOCUMENTS,
    'from_dac': mutations.SET_SEARCH_FROM_DAC,
};

export default {
    components: {
        Modal,
        SaveSearch,
    },
    props: {
        isAdmin: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            endpoints: {
                ADD_STORED_SEARCH,
            },
            showSaveSearchModal: false,
        };
    },
    computed: {
        query: {
            get() {
                return this.$store.state.search.query;
            },
        },
        recentSearchId: {
            get() {
                if (this.$store.state.search) {
                    return this.$store.state.search.recent_search_id;
                }

                return null
            },
        },
    },
    watch: {},
    mounted() {},
    methods: {
        removeArrayFilter(filterKey, filter, isObject = true) {
            if (isObject) {
                this.$store.commit(MUTATIONS_MAP[filterKey], reject(this.query[filterKey], filter));
            } else {
                this.$store.commit(MUTATIONS_MAP[filterKey], pull(this.query[filterKey], filter));
            }
            this.$store.dispatch('search');
        },
        removeBoolFilter(filterKey) {
            this.$store.commit(MUTATIONS_MAP[filterKey], null);
            this.$store.dispatch('search');
        },
        removeStringFilter(filterKey) {
            this.$store.commit(MUTATIONS_MAP[filterKey], null);
            this.$store.dispatch('search');
        },
        removeStatus() {
            this.$store.commit(MUTATIONS_MAP['status'], {id: 0, name: 'All', value: 'all'});
            this.$store.dispatch('search');
        }
    }
};
</script>
