import {
    USER_WORKSPACES_LIST,
    USER_WORKSPACES_CREATE,
} from 'Root/endpoints';
import types from '../mutation-types';
import { uniqBy } from 'lodash';
import axios from 'axios';
import Notification from '@/Notifications/NotificationModel';

const user = {
    namespaced: true,
    state: {
        user: null,
        selectedAssets: [],
        associations: [],
        workspaces: [],
        groups: [],
        workOrders: [],
    },
    mutations: {
        // User Stuff
        [types.SET_USER](state, user) {
            state.user = user;
        },
        [types.SET_USER_ASSOCIATIONS](state, associations) {
            state.associations = associations;
        },
        [types.SET_USER_GROUPS](state, groups) {
            state.groups = groups;
        },
        [types.SET_USER_WORKSPACES](state, workspaces) {
            state.workspaces = workspaces;
        },
        [types.SET_USER_WORK_ORDERS](state, workOrders) {
            state.workOrders = workOrders;
        },
        [types.SET_USER_SELECTED_ASSETS](state, selected) {
            state.selectedAssets = uniqBy(selected, 'asset_id');
        },
    },
    actions: {
        getSelectedAssets({ commit }) {
            const selectedAssets = JSON.parse(localStorage.getItem('user.selectedAssets')) || [];
            commit(types.SET_USER_SELECTED_ASSETS, selectedAssets);
        },
        removeSelectedAssets({ commit }) {
            localStorage.setItem('user.selectedAssets', JSON.stringify([]));
            commit(types.SET_USER_SELECTED_ASSETS, []);
        },
        setSelectedAssets({ commit }, assets) {
            localStorage.setItem('user.selectedAssets', JSON.stringify(assets));
            commit(types.SET_USER_SELECTED_ASSETS, assets);
        },

        async getWorkspaces({commit, state, dispatch}) {
            try {
                const response = await axios.get(USER_WORKSPACES_LIST + '?includes=thumbnail,links');
                commit(types.SET_USER_WORKSPACES, response.data.data);
                return response.data.data;
            } catch (error) {
                console.log(error);
            }
        },
        async createWorkspace({commit, state, dispatch}, {name, asset_ids }) {
            return axios.post(USER_WORKSPACES_CREATE, { name, asset_ids })
                .then(response => {
                    return response.data.data;
                }).catch(error => {
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    );
                });
        },
        async addAssetsToWorkspace({commit, state, dispatch}, {assets, workspace}) {
            return axios.post(`/api/workspaces/${workspace.workspace_id}/assets`, {
                asset_ids: assets.map(asset => asset.asset_id)
            })
                .then(response => {
                    dispatch('getWorkspaces');
                    return response.data.data;
                }).catch(error => {
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    );
                });
        },

        async moveAssetsToWorkspace({ dispatch }, { assets, source_workspace, target_workspace }) {
          return axios.put(`/api/workspaces/${source_workspace}/assets/move`, {
            asset_ids: assets.map(asset => asset.asset_id),
            target_workspace_id: target_workspace.workspace_id,
          })
            .then((response) => {
              return dispatch('fetchWorkspaceAssets');
            })
            .catch((error) => {
              dispatch('addNotification', new Notification(error.response.data.message, 'danger'), { root: true });
            });
        },
    },
};

export default user;
