import NewsList from '@/NewsList';
import VueRouter from 'vue-router';

export default new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '',
            name: 'news-list',
            component: NewsList
        },
    ]
});
