<template>
<drawer>
    <template #trigger="{ toggleDrawer }">
        <button
            :class="drawerTogglerClasses"
            :disabled="noFilesAvailable"
            @click="toggleDrawer"
            @keyup.esc="toggleDrawer"
        >
            Download
            <span class="assetCard__chevron"></span>
        </button>
    </template>

    <template #drawer>
        <ul :class="drawerClasses">
            <li
                v-if="!asset.links.downloads"
                class="downloadLinks--link asset__tabItem"
            >
                <span class="inline-block text-left">No downloads available</span>
            </li>
            <li
                v-for="(link, type) in assetDownloadLinks"
                :key="type"
                class="downloadLinks--link asset__tabItem"
                @click.prevent="getConfirmation(link)"
                v-html="urlDecode(link)"
            />

            <confirm
                :visible="showConfirm"
                text=""
                confirm-text="I agree to these terms"
                cancel-text="Cancel download"
                @update:visible="showConfirm = $event"
                @confirmed="download"
                @cancelled="showConfirm = false"
            >
                <template #text>
                    <div class="modal__copy">
                        <p>
                            <span class="font-bold">PLEASE NOTE:</span>
                            The Asset you are about to download and use is Rights Managed.
                            Please read the Usage Description and adhere to the specified
                            usage. By down-loading this asset you are agreeing to the usage
                            terms. Please <!-- I have no idea why clicking this link
                            wont fire so I added a method to do it. --> <a
                                @click="theHeck"
                                class="link"
                                target="_blank"
                                href="/resources/contact"
                            >contact us</a> before downloading if you have any questions.
                        </p>
                        <p v-if="asset.usage_description" class="my-2">
                            <span class="font-bold">Usage Description:</span> <span v-text="asset.usage_description" />
                        </p>
                        <p v-if="asset.instructions" class="my-2">
                            <span class="font-bold">Instructions:</span> <span v-text="asset.instructions" />
                        </p>
                        <p v-if="asset.deactivation_date" class="my-2">
                            <span class="font-bold">Expiration Date:</span> <span v-text="asset.deactivation_date" />
                        </p>
                    </div>
                </template>
            </confirm>
        </ul>
    </template>
</drawer>
</template>

<script>
import Drawer from '@/Drawer';
import Confirm from '@/Confirm';

export default {
    components: {
        Drawer,
        Confirm
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        extraDrawerTogglerClasses: {
            type: Array,
            default: null,
            required: false,
        },
        extraDrawerClasses: {
            type: Array,
            default: null,
            required: false,
        },
        iconStyle: {
            type: String,
            default: 'regular',
            required: false,
        }
    },
    data() {
        return {
            showConfirm: false,
            isConfirmed: false,
            link: null,
        };
    },
    computed: {
        drawerTogglerClasses() {
            let classes = [
                'assetCard__button',
            ];

            if (this.extraDrawerTogglerClasses) {
                classes = classes.concat(this.extraDrawerTogglerClasses);
            }

            return classes.join(' ');
        },
        drawerClasses() {
            let classes = [
                'downloadLinks drawer__links',
            ];

            if (this.extraDrawerClasses) {
                classes = classes.concat(this.extraDrawerClasses);
            }

            return classes.join(' ');
        },
        faStyles() {

            let classes = [
                'link--icon',
                '--vertical',
                'fa-fw',
                'fa-angle-down',
                'text-coral',
            ];

            if (this.iconStyle === 'regular') {
                classes.push('far');
            }

            if (this.iconStyle === 'light') {
                classes.push('fal');
            }

            return classes.join(' ');
        },
        noFilesAvailable() {
            return this.asset.files.length === 0;
        },
        assetDownloadLinks() {
            if (this.asset.links) {

                if (this.isTemplate) {
                    if (this.isTemplate) {
                        if (this.asset.links.downloads.high) {
                            return {
                                'high': this.asset.links.downloads.high,
                            };
                        }

                        return {};
                    }
                }
                return this.asset.links.downloads;
            }

            return {};
        },
        isTemplate() {
            if (this.asset.asset_type && this.asset.asset_type.name == 'Template') {
                return true;
            }
            return false;
        },
    },
    methods: {
        // I can't figure out why the link in the confirm text doesn't want to
        // click. What the heck?!
        theHeck(event) {
            window.location = event.target.href;
        },
        getConfirmation(link) {
            this.link = link;

            if (!this.asset.rights_managed || this.isConfirmed === true) {
                this.download();
                return;
            }

            this.showConfirm = true;
        },
        download() {
            let div = document.createElement('div');
            div.innerHTML = this.link;
            window.location = div.firstChild.href;
            this.showConfirm = false;
        },
        urlDecode(link) {
            return decodeURI(link); // v-html="link" will double encode the URL.
        }
    },
};
</script>

<style>

</style>
