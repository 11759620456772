<template>
<section class="">
    <div v-if="isLoading">
        <i class="far fa-fw fa-lg fa-spin fa-spinner" />
        <span>Loading associations</span>
    </div>
    <draggable
        v-model="orderedAssociations"
        class="user--associations cards"
        handle=".card--grip"
    >
        <div
            v-for="association in orderedAssociations"
            :key="association.brand_id ? association.brand_id : association.property_id"
            class="card"
        >
        <div class="card__imageWrapper">
            <img
            :src="getThumbnailUrl(association.logo ? association.logo.data : null)"
            :alt="`${association.name} Logo`"
            class="card__image"
            >
        </div>
        
        <div class="card__contentWrapper">
            
                <p
                class="card__title"
                v-text="association.name"
                />
            
            <!--<div class="card--footer" v-if="association.links">
                <a
                v-for="(link, type) in association.links.data"
                :key="type"
                class="card--footerLink pill --rounded --coral --xsmall"
                :href="link"
                v-text="type"
                />
            </div>--> 
        </div>
    </div>
    </draggable>
</section>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import endpoints from 'Root/endpoints';
import { SET_USER_ASSOCIATIONS }  from 'Stores/Frontend/mutation-types';
import { mapState } from 'vuex';
import draggable from 'vuedraggable';

export default {
    components: {
        draggable
    },
    data() {
        return {
            order: null,
            isLoading: false,
        };
    },
    computed: {
        ...mapState({
            associations: state => state.user.associations,
        }),
        orderedAssociations: {
            set(associations) {
                let order = {};

                associations.forEach((association, i) => {
                    if (association.brand_id) {
                        order['b_' + association.brand_id.toLowerCase()] = i;
                    }

                    if (association.property_id) {
                        order['p_' + association.property_id] = i;
                    }
                });

                this.$store.commit('user/' + SET_USER_ASSOCIATIONS, associations);

                this.order = order;

                window.localStorage.setItem('associations_order', JSON.stringify(order));
            },
            get() {
                let order = this.order;

                if (!order) {
                    try {
                        order = JSON.parse(window.localStorage.getItem('associations_order'));
                    } catch (error) {
                        return this.associations;
                    }
                }


                let associations = this.associations.map((association, i) => {
                    let id = null;

                    if (association.brand_id) {
                        id = 'b_' + association.brand_id.toLowerCase();
                    }

                    if (association.property_id) {
                        id = 'p_' + association.property_id;
                    }

                    if (order && order[id]) {
                        association.order = order[id];
                    } else {
                        order = i;
                    }

                    return association;
                });

                return sortBy(associations, 'order');
            }
        }
    },
    mounted() {
        this.fetchAssociations();
    },
    methods: {
        fetchAssociations() {
            if (this.associations.data) {
                return;
            }

            this.isLoading = true;

            axios.get(endpoints.USER_ASSOCIATIONS_LIST + '?includes=thumbnail,links,logo,logo.files')
                .then(res => {
                    this.$store.commit('user/' + SET_USER_ASSOCIATIONS, res.data.data);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        getThumbnailUrl(logo) {
            if (!logo) {
                return '/sup/img/assets/no_200x200.gif';
            };

            return logo.path;
        },
        saveOrder(something, something1, something2) {
            this.orderedAssociations = this.associations;
        }
    }
};
</script>

<style>

</style>
