<template>
    <section class="assetListing">
        <template v-if="assets.length">
            <div v-if="showControls" class="full-width list-format-toggle">
                <i @click="formatAs('columns')" class="fal fa-th" :class="{'inactive':!displayColumns}" /> 
                <i @click="formatAs('full-width')" class="fal fa-id-card" :class="{'inactive':!displayFullWidth}" /> 
                <i @click="formatAs('carousel')" class="fal fa-presentation" :class="{'inactive':!displayCarousel}" />
            </div>
            
            <asset-listing-controls
                v-if="showControls"
                :active-status="activeStatus"
                :all-selected="allSelected"
                @select-all="selectAll"
            />
            <div v-if="displayCarousel" class="full-width">
                <carousel :assets="assets" />
            </div>

            <div v-else-if="displayColumns" class="full-width">
                <lazy-component
                    v-for="(chunk, i) in chunked"
                    class="assetListing__contentRow"
                    :key="i"
                >
                    <div
                        v-for="asset in chunk"
                        :key="asset.asset_id"
                        class="assetListing__content"
                    >
                        <asset-card
                            :is-admin="isAdmin"
                            :selected="isSelected(asset)"
                            :asset="asset"
                            @input="updateSelected($event, asset)"
                        />
                    </div>
                </lazy-component>
            </div>
            <div v-else class="full-width">
                <lazy-component
                    v-for="asset in assets"
                    :key="asset.asset_id"
                >
                    <asset-card-full
                        :is-admin="isAdmin"
                        :selected="isSelected(asset)"
                        :asset="asset"
                        @input="updateSelected($event, asset)"
                    />
                </lazy-component>
            </div>
            
            <asset-listing-controls
                v-if="showControls"
                :active-status="activeStatus"
                :all-selected="allSelected"
                @select-all="selectAll"
            />
        </template>

        <template v-if="!hasSearched && !assets.length">
            <div class="card searchResults__simpleCard">
                <span>Search for assets using the form above and/or the filters to the left.</span>
            </div>
        </template>

        <template v-if="hasSearched && !assets.length">
            <div class="card searchResults__simpleCard">
                <span>Your search did not match any results</span>
            </div>
        </template>
    </section>
</template>

<script>
import AssetCard from '@/AssetCard';
import AssetCardFull from '@/AssetCardFull';
import AssetListingControls from '@/AssetListingControls';
import Carousel from '@/AssetListingCarousel';
import { reject, find, differenceWith, isEqual, chunk } from 'lodash';
import {
    SET_SEARCH_DISPLAY_FORMAT,
} from 'Stores/Frontend/mutation-types';
import LazyComponent from 'v-lazy-component';
import { getParams } from 'vanilla-js-url';

export default {
    components: {
        AssetCard,
        AssetCardFull,
        AssetListingControls,
        Carousel,
        LazyComponent,
    },
    props: {
        assets: {
            required: false,
            default: () => [],
            type: Array,
        },
        activeStatus: {
            required: true,
            type: Object,
        },
        isAdmin: {
            required: false,
            default: false,
            type: Boolean,
        },
        showControls: {
            required: false,
            default: true,
            type: Boolean,
        },
    },
    mounted() {
        const searchQueryParams = getParams(window.location.search);

        if (searchQueryParams.hasOwnProperty('display')) {
            let display = searchQueryParams.display;

            if (display != this.displayFormat) {
                this.formatAs(display)
            }
        }
    },
    computed: {
        allSelected() {
            return this.assets.every((asset) => find(this.selected, asset));
        },
        selected: {
            get() {
                return this.$store.state.user.selectedAssets;
            },
            set(assets) {
                this.$store.dispatch('user/setSelectedAssets', assets);
            }
        },
        hasSearched() {
            return this.$store.state.search.hasSearched;
        },
        chunked() {
            return chunk(this.assets, 3);
        },
        displayFormat() {
            return this.$store.getters.display;
        },
        displayColumns() {
            return this.displayFormat == 'columns';
        },
        displayFullWidth() {
            return this.displayFormat == 'full-width';
        },
        displayCarousel() {
            return this.displayFormat == 'carousel';
        },
    },
    methods: {
        updateSelected(isChecked, asset) {
            if (isChecked) {
                let selected = this.selected;
                selected.push(asset);
                this.selected = selected;
            } else {
                this.selected = reject(this.selected, asset);
            }
        },
        selectAll(event) {
            if (event.target.checked) {
                let selected = this.selected;
                selected.push(...this.assets);
                this.selected = selected;
            } else {
                this.selected = differenceWith(this.selected, this.assets, isEqual);
            }
        },
        isSelected(asset) {
            return find(this.selected, asset) ? true : false;
        },
        formatAs(format) {
            this.$store.commit(SET_SEARCH_DISPLAY_FORMAT, format);
        },
    }
};
</script>