var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"searchInputGroup"},[_c('label',{class:[
            'label',
            {'sr-only': !_vm.showLabel}
        ],attrs:{"for":_vm.label}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('div',{staticClass:"searchInputGroup__checkboxGroup"},[(_vm.search)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"input",attrs:{"id":_vm.filterId,"type":"text","placeholder":_vm.placeholder ? _vm.placeholder : null},domProps:{"value":(_vm.filter)},on:{"input":[function($event){if($event.target.composing)return;_vm.filter=$event.target.value},function($event){return _vm.$emit('update:filter', $event.target.value)}]}}):_vm._e(),_vm._v(" "),(_vm.selected.length)?_c('div',{staticClass:"searchInputGroup__selected"},[(_vm.selected.length === _vm.options.length)?_c('span',{staticClass:"pill",on:{"click":function($event){_vm.selected = []}}},[_vm._v("\n                All "+_vm._s(_vm.labelPlural)+" selected\n            ")]):_vm._l((_vm.selected),function(item,i){return _c('label',{key:i,staticClass:"pill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"sr-only",attrs:{"type":"checkbox"},domProps:{"value":item,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}}),_vm._v("\n                "+_vm._s(item[_vm.displayKey])+"\n                "),_c('span',{staticClass:"exit"})])})],2):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"searchInputGroup__list",attrs:{"role":"list"}},[(_vm.options.length === 0 && !_vm.selected)?_c('li',[_vm._v("\n                No "+_vm._s(_vm.labelPlural)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.options.length > 0)?_c('li',{class:[
                    'searchInputGroup__item',
                    {'text-slate-lightText': _vm.selected.length === _vm.options.length}
                ],attrs:{"role":"listitem","aria-controls":"select-option","tabindex":"0","aria-selected":"false"},on:{"click":_vm.toggleOptions,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleOptions()}}},[_vm._v("\n                All "+_vm._s(_vm.labelPlural)+"\n            ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredOptions),function(item,i){return _c('li',{key:i,staticClass:"searchInputGroup__item"},[_c('label',{staticClass:"searchInputGroup__label",attrs:{"role":"listitem","aria-controls":"select-option","tabindex":"0","aria-selected":"false"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleOption(item)}}},[_c('span',{class:[
                            {'text-slate-lightText': _vm.isSelected(item)},
                        ],domProps:{"innerHTML":_vm._s(_vm.displayKeyFilter(item))}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"invisible sr-only",attrs:{"type":"checkbox"},domProps:{"value":item,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}})])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }