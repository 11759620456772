<template>
<div class="">
    <vue-date-picker
        :id="id"
        :value="date"
        :typeable="true"
        format="MM/dd/yyyy"
        :inline="inline"
        placeholder="mm/dd/yyyy"
        calendar-class="datePicker--calendar"
        input-class="datePicker--input"
        wrapper-class="datePicker--wrapper"
        @input="updateDate"
    />

    <input
        :name="name"
        type="hidden"
        :value="formatter(date)"
    >
</div>
</template>

<script>
import VueDatePicker from 'vuejs-datepicker';
import { format as formatDate, startOfToday, parse } from 'date-fns';

export default {
    components: {
        VueDatePicker,
    },
    props: {
        value: {
            default: () => null,
            required: false,
            validator: function (value) {
                return (
                    value === null || typeof value === 'string' || value instanceof Date
                );
            }
        },
        format: {
            default: 'MM/dd/yyyy',
            required: false,
            type: String,
        },
        name: {
            required: true,
            type: String
        },
        id: {
            required: false,
            type: String,
            default: null,
        },
        inline: {
            required: false,
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            internalDate: null,
        };
    },
    computed: {
        date: {
            get() {
                if (!this.internalDate) {
                    return null;
                }

                let date = this.internalDate;

                if (typeof date === 'string') {
                    date = this.parseDate(date);
                }

                return date;
            },
            set(value) {
                value = this.formatter(value);

                if (value === this.internalDate) {
                    return;
                }

                this.internalDate = value;

                this.$emit('input', this.internalDate);
            }
        }
    },
    watch: {
        value(value) {
            this.internalDate = value;
        }
    },
    mounted() {
        if (typeof this.value === 'string') {
            this.internalDate = this.parseDate(this.value);
        } else {
            this.internalDate = this.value;
        }
    },
    methods: {
        formatter(date) {
            if (!date) {
                return null;
            }

            if (typeof date == 'string') {
                date = parse(date, 'MM/dd/yyyy', new Date);
            }

            return formatDate(date, this.format);
        },
        updateDate(date) {
            this.date = date;
        },
        parseDate(date) {
            return parse(date, this.format, startOfToday());
        }
    }
};
</script>

<style lang="scss">
.datePicker--calendar {
    @apply border-slate-lighter rounded shadow #{!important};

    .disabled {
        @apply text-slate-light #{!important};
    }

    .cell {
        @apply rounded;

        &:not(.blank):not(.disabled).day,
        &:not(.blank):not(.disabled).month,
        &:not(.blank):not(.disabled).year {
            &:hover {
                @apply border-coral #{!important};
            }
        }

        &.selected {
            @apply bg-coral #{!important};

            &:hover {
                @apply bg-coral #{!important};
            }

            &.highlighted {
                @apply bg-coral #{!important};
            }
        }

        &.highlighted {
            @apply bg-coral #{!important};

            &.disabled {
                @apply text-slate-light #{!important};
            }
        }
    }

    header {
        .up:not(.disabled):hover,
        .prev:not(.disabled):hover,
        .next:not(.disabled):hover {
            @apply bg-slate-alt underline #{!important};
        }

    }

    .prev::after {
        @apply border-r-coral #{!important};
    }

    .next::after {
        @apply border-l-coral #{!important};
    }

    &.grey {
        @apply text-slate-light #{!important};
    }
}

.datePicker--input {
    @apply
        border
        border-slate-lighter
        w-full
        text-slate-light
        focus:ring-0
        focus:outline-none
        focus:inset-1
        focus:border-coral-dark
        rounded
        mb-4
        p-2;
}

.datePicker--input:focus{
    border-color: #0096D6;
}

.datePicker--calendar .prev::after{
    border-right: 10px solid #0096D6!important;
    border-right-color: #0096D6!important;
}

.datePicker--calendar .next::after{
    border-left: 10px solid #0096D6!important;
    border-left-color: #0096D6!important;
}

</style>
