export const BRANDS_LIST = '/api/structure/brands';
export const PROPERTIES_LIST = '/api/structure/properties';
export const ASSET_TYPES_LIST = '/api/structure/asset-types';
export const SEARCHES_RECENT_LIST = '/api/structure/searches/recent';
export const SEARCHES_STORED_LIST = '/api/structure/searches/stored';
export const USER_ASSOCIATIONS_LIST = '/api/structure/user/associations';
export const USER_WORKSPACES_LIST = '/api/structure/user/workspaces';
export const USER_WORKSPACES_CREATE = '/api/structure/user/workspaces';
export const USER_WORKSPACES_UPDATE = '/api/structure/user/workspaces';
export const USER_WORK_ORDERS_LIST = '/api/structure/user/work-orders';
export const USER_USER_GROUPS_LIST = '/api/structure/user/groups';
export const USER_SEARCH = '/sitemanager/users/search';
export const SEARCH = '/api/structure/search';
export const DOWNLOAD_ASSETS = '/download/multiple';
export const ASSET_LIST = '/api/structure/assets';
export const ASSET_UPDATE = '/api/structure/assets';
export const ASSET_UPDATE_VISIBLE_TO_API = '/api/structure/assets/api-visibility';
export const ADD_STORED_SEARCH = '/sitemanager/assets/stored_searches?action=add';
export const SUBJECTS_LIST = '/api/structure/subjects';
export const ROOM_CODES_LIST = '/api/structure/room-codes';
export const UPLOAD_RIGHTS_DOCS = '/ajax/rights-documents';
export const DELETE_RIGHTS_DOCS = '/ajax/rights-documents';
export const USER_GROUPS_LIST = '/api/structure/user-groups';
export const CONTAINERS_LIST = '/sitemanager/mdam/containers';
export const DATAPOINTS_LIST = '/sitemanager/mdam/datapoints';
export const SYSTEM_MESSAGES_LIST = '/ajax/system-messages';

export default {
    BRANDS_LIST,
    PROPERTIES_LIST,
    ASSET_TYPES_LIST,
    SEARCHES_RECENT_LIST,
    SEARCHES_STORED_LIST,
    USER_ASSOCIATIONS_LIST,
    USER_WORKSPACES_LIST,
    USER_WORKSPACES_CREATE,
    USER_WORK_ORDERS_LIST,
    USER_USER_GROUPS_LIST,
    USER_SEARCH,
    SEARCH,
    DOWNLOAD_ASSETS,
    ASSET_LIST,
    ASSET_UPDATE,
    ADD_STORED_SEARCH,
    ASSET_UPDATE_VISIBLE_TO_API,
    SUBJECTS_LIST,
    ROOM_CODES_LIST,
    UPLOAD_RIGHTS_DOCS,
    DELETE_RIGHTS_DOCS,
    USER_GROUPS_LIST,
    CONTAINERS_LIST,
    DATAPOINTS_LIST,
    SYSTEM_MESSAGES_LIST,
};
