<template>
	<vue-flux
		:options="options"
		:images="images"
		:transitions="transitions"
		:captions="links">

		<template v-slot:caption>
            <flux-caption v-slot="captionProps">
                <a :href="parseCaptionText(captionProps)">
                    View Details
                </a>
            </flux-caption>
        </template>

		<template v-slot:preloader>
			<flux-preloader>
				<div class="fa-3x">
					<i class="fas fa-spinner fa-spin"></i>
				</div>
			</flux-preloader>
		</template>
		
		<template v-slot:controls>
			<flux-controls />
		</template>

		<template v-slot:index>
			<flux-index />
		</template>
	</vue-flux>
</template>
<script>
import {
	Img,
	FluxPreloader,
	FluxCaption,
	FluxControls,
	FluxIndex,
	VueFlux,
} from 'vue-flux';
import { fullSizeImage } from '@/utilities/assetUtilities.js';

export default {
    components: {
        Img,
		FluxPreloader,
		FluxCaption,
		FluxControls,
		FluxIndex,
		VueFlux,
		fullSizeImage
    },
    props: {
        assets: {
            required: false,
            default: () => [],
            type: Array,
        },
    },
    data: () => ({
        options: {
            autohideTime: 0,
            autoplay: false,
            delay: 4000,
            totalDuration:100,
        },
        transitions: [
            {
                name: 'fade',
                options: {
                    totalDuration: 100
                }
            }
        ]
    }),
    computed: {
        images() {
            return this.assets.map(a => fullSizeImage(a));
        },
        links(){
            return this.assets.map(a => a.links.view);
        },
    },
    methods: {
        parseCaptionText(caption) {
            return caption.text;
        },
    },
}
</script>
<style>
    .vue-flux .flux-caption {
        font-size: 1.1rem;
        font-weight: 700;
    }
    .vue-flux .flux-caption a {
        color: #FFF;
    }
    .flux-controls button.play,
    .flux-controls button.pause {
        display: none;
    }
</style>
