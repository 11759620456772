<template>
<div
    :id="`searchInput--${id}`"
    class="searchInputGroup relative text-sm"
>
    <label class="searchInput__label label" :for="id">
        {{ label }}
    </label>

    <div
        :class="[
            'searchInput__optionsWrapper',
            viewOptions ? 'drop-shadow-bottom' : '',
        ]"
        ref="searchInputContainer"
    >
        <div class="searchInput__filterWrapper">
            <input
                :id="id"
                ref="filterInput"
                v-model="filter"
                type="text"
                :class="['searchInput__filter']"
                :placeholder="placeholder"
                @click="toggleOptions"
                @keyup.esc="toggleOptions"
                @input="liveSearch ? searchOptions() : null"
            >
            <i
                v-if="isLoading"
                class="searchInput--loading far fa-spinner fa-spin fa-lg fa-fw"
            />
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <ul
                v-show="viewOptions"
                class="searchInput__options"
                ref="searchInputOptions"
            >
                <li
                    v-if="!filter && filteredOptions.length > 1"
                    class="searchInput__option"
                >
                    <span
                        @click="toggleSelected()"
                    >
                        All {{ labelPlural }}
                    </span>
                </li>

                <li
                    v-if="liveSearch && !filter && searchHelpText"
                    class="searchInput__option"
                >
                    {{ searchHelpText }}
                </li>

                <li
                    v-if="filter && !filteredOptions.length"
                    class="searchInput__option"
                >
                    No {{ labelPlural.toLowerCase() }} were found
                </li>

                <li
                    v-for="(option, i) in filteredOptions"
                    :key="i"
                >
                    <label
                        :class="[
                            'searchInput__option',
                            isSelected(option) ? '--selected' : '',
                        ]"
                    >
                        {{ optionTextTransform(option) }}
                        <input
                            v-model="selected"
                            type="checkbox"
                            class="sr-only"
                            :value="option"
                        >
                    </label>
                </li>
            </ul>
        </transition>
    </div>

    <div
        v-if="selected.length"
        class="searchInput__selectedSearch selected"
    >
        <span
            v-if="!liveSearch && selected.length === options.length"
            class="pill"
            @click="selected = []"
        >
            All {{ labelPlural }} selected
            <span class="exit"></span>        </span>

        <label
            v-for="(item, i) in visibleSelected"
            :key="i"
            class="pill"
        >
            <input
                v-model="selected"
                type="checkbox"
                :value="item"
                class="sr-only"
            >
            {{ selectedTextTransform(item) }}
            <span class="exit"></span>        </label>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import { debounce } from 'lodash';
import SearchInputMixins from '@/Mixins/SearchInputMixins';

export default {
    components: {},
    mixins: [
        SearchInputMixins,
    ],
    props: {
        id: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            default: () => [],
            type: Array
        },
        label: {
            required: true,
            type: String,
        },
        labelPlural: {
            required: true,
            type: String,
        },
        endpoint: {
            required: false,
            type: String,
            default: null,
        },
        filterBy: {
            required: true,
            type: String
        },
        searchDisplayKey: {
            required: true,
            type: String,
        },
        selectedDisplayKey: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
            default: null
        },
        liveSearch: {
            required: false,
            default: false,
            type: Boolean,
        },
        searchKey: {
            required: false,
            type: String,
            default: null,
        },
        searchParam: {
            required: false,
            type: String,
            default: null,
        },
        optionTextTransform: {
            required: false,
            type: Function,
            default(option) {
                return option[this.searchDisplayKey];
            }
        },
        selectedTextTransform: {
            required: false,
            type: Function,
            default(option) {
                return option[this.selectedDisplayKey];
            }
        },
        searchHelpText: {
            required: false,
            type: String,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            filter: null,
            internalValue: [],
            isLoading: false,
        };
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(selected) {
                this.internalValue = selected;
                this.$emit('input', this.internalValue);
                this.closeOptions();
            }
        },
        filteredOptions() {
            let filtered = this.options;

            if (this.liveSearch) {
                return this.options;
            }

            if (this.filter) {
                return filtered.filter(option => {
                    return option[this.filterBy].toLowerCase().includes(this.filter.toLowerCase());
                });
            }

            return filtered;
        },
        visibleSelected() {
            if (!this.liveSearch && this.selected.length === this.options.length) {
                return [];
            }

            return this.selected;
        }
    },
    mounted() {
        if (this.endpoint && !this.liveSearch) {
            this.fetchOptions();
        }

        this.internalValue = this.value;
    },
    methods: {
        fetchOptions() {
            if (this.endpoint) {
                axios.get(this.endpoint)
                    .then(response => {
                        this.options = response.data.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        searchOptions: debounce(function() {
            if (!this.filter) {
                this.options = [];
                return false;
            }

            let params = {};

            params[this.searchParam] = this.filter;

            this.isLoading = true;

            axios.get(this.endpoint, { params })
                .then(response => {
                    this.options = response.data.data;
                    this.showOptions();
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.isLoading = false;
                });
        }, 300),
        isSelected(option) {
            let filterBy = this.filterBy;
            return this.selected.find(selected => selected[filterBy] === option[filterBy]);
        },
        toggleSelected() {
            if (this.selected.length === this.options.length) {
                this.selected = [];
            } else {
                this.selected = this.options;
            }

        },
    }
};
</script>

<style>

</style>
