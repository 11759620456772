<template>
<ul class="linkNav__links">
    <li
        v-for="link in links"
        :key="link.url"
        class="linkNav__item"
    >
        <a
            :class="[
                'linkNav__link',
                isActive(link) ? '--active' : '',
            ]"
            :href="link.url"
        >
            <span
                :class="[
                    `-${link.icon}`,
                    'linkNav__icon',
                ]"
            ></span>
            <span
                :class="[
                    'linkNav__text',
                    'inline-block',
                    'overflow-hidden',
                    isOpen ? 'ml-5.5' : '',
                    isOpen ? 'w-full' : 'w-0',
                ]"
                v-text="link.text"
            />
        </a>
    </li>
</ul>
</template>

<script>
export default {
    props: {
        links: {
            required: true,
            type: Array,
        },
        isOpen: {
            required: false,
            default: true,
            type: Boolean
        }
    },
    data() {
        return {};
    },
    methods: {
        isActive(link) {
            let url = new URL(window.location);
            return link.url === url.pathname;
        },
    },
};
</script>
