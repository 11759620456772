var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:[
        'collapsingMenu',
        _vm.opened ? _vm.getOpenedClasses() : '--closed',
        _vm.sticky ? '--sticky' : null,
    ]},[_c('div',{staticClass:"collapsingMenu__wrapper",class:{
            '--sticky': _vm.sticky,
        }},[_c('div',{staticClass:"collapsingMenu__controls",on:{"click":function($event){$event.preventDefault();_vm.opened = !_vm.opened}}},[(_vm.icon)?_c('span',{staticClass:"collapsingMenu__icon"}):_vm._e(),_vm._v(" "),(_vm.label && _vm.opened)?_c('h3',{staticClass:"collapsingMenu__header",domProps:{"textContent":_vm._s(_vm.label)}}):_vm._e(),_vm._v(" "),_c('button',{class:[
                    'collapsingMenu__toggle',
                    _vm.opened ? 'ml-auto' : null,
                ]},[_c('span',{class:[
                        'collapsingMenu__toggleIcon',
                        _vm.opened ? '' : '--rotate',
                    ]})])]),_vm._v(" "),_c('div',{class:[
                'collapsingMenu__content',
                _vm.opened ? '--open' : '--close',
            ]},[_vm._t("content",null,{"isOpen":_vm.opened})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }