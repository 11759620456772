<template>
    <div>
        <div v-if="error" class="error mb-4">
            {{ error }}
        </div>

        <div class="form-group pb-4">
            <label for="name">Search Name</label>

            <input
                v-model="name"
                type="text"
                name="name"
                id="name"
                class="form-control"
            >

            <small>Must be at least 3 characters.</small>
        </div>

        <div v-if="recentSearch" class="alert alert-info">
            <p><strong>Recent Search:</strong> {{ recentSearch.name }}</p>
        </div>

        <button
            class="button -secondary"
            :disabled="loading || !valid"
            @click="save"
        >
            Save
        </button>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        recentSearchId: {
            type: Number,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data: () => ({
        name: null,
        recentSearch: {},
        error: null,
        loading: false,
    }),
    mounted() {
        if (this.recentSearchId) {
            this.fetchRecentSearch();
        }
    },
    computed: {
        valid() {
            return this.name !== null
                && this.name !== ''
                && this.name.length >= 3
                && this.name.length <= 255;
        },
    },
    methods: {
        save() {
            this.loading = true;
            this.error = null;

            axios.post('/api/structure/searches/stored', {
                name: this.name,
                query: this.query,
                recent_search_id: this.recentSearchId,
            }).then((response) => {
                this.$emit('saved', response.data.data);
            }).catch((error) => {
                this.error = error.response.data.message || 'Could not save search, please try again later.';
            }).finally(() => this.loading = false);
        },

        fetchRecentSearch() {
            axios.get(`/api/structure/searches/recent/${this.recentSearchId}`).then((response) => {
                this.recentSearch = response.data.data;
            }).catch((error) => {
                this.error = error.response.data.message;
            });
        },
    },
};
</script>