<template>
    <div class="workspaceActions">
        <div class="modal__controls">
            <button
                class="assetCard__button"
                @click="toggleModal"
                @keyup.esc="toggleModal"
            >
                Add To Workspace
                <span class="assetCard__plus"></span>
            </button>
        </div>
        <modal
            :visible.sync="modal.isOpen"
            :transparent="false"
        >
            <div v-if="confirmAction === false" class="workspaceActions__add">
                <div class="row">
                    <input
                        v-model="workspaceFilter"
                        placeholder="Workspace name"
                    >
                </div>

                <div v-if="workspaceFilter" class="row">
                    <span>"{{ workspaceFilter }}"</span>
                    <button
                        class="button"
                        @click="confirmNewWorkspace()"
                    >
                        Create
                    </button>
                </div>

                <div class="row">
                    <ul class="list -inline" v-if="filteredWorkspaces.length">
                        <li
                            v-for="workspace in filteredWorkspaces"
                            :key="workspace.id"
                            class="list__item"
                        >
                            <span>{{ getTruncatedName(workspace) }}</span>
                            <button
                                class="button -add"
                                @click.prevent="confirmAddToWorkspace(workspace)"
                            >
                                Add
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else class="workspaceActions__wrap">
                <div v-if="isNewWorkspace" class="workspaceActions__add">
                    <p>
                        Create new workspace titled <span class="font-bold">"{{ workspaceFilter }}"</span>?
                    </p>
                    <div class="text-center">
                        <button
                            class="button"
                            @click="createNewWorkspace()"
                        >
                            Create
                        </button>
                        <button
                            class="button -cancel"
                            @click="confirmAction = false"
                        >
                            Cancel
                        </button>
                    </div>
                </div>

                <div v-if="!isNewWorkspace && selectedWorkspace" class="workspaceActions__add">
                    <p>
                        Add to existing workspace titled <span class="font-bold">"{{ selectedWorkspace.name }}"</span>?
                    </p>
                    <div class="text-center">
                        <button
                            class="button -add"
                            @click="addAssetToWorkspace()"
                        >
                            Add
                        </button>
                        <button
                            class="button -cancel"
                            @click="confirmAction = false"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '@/Modal';
import Notification from '@/Notifications/NotificationModel';

export default {
    components: {
        Modal,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        workspaces: {
            type: Array,
            required: true,
        },
        extraDrawerTogglerClasses: {
            type: Array,
            default: null,
            required: false,
        },
        extraDrawerClasses: {
            type: Array,
            default: null,
            required: false,
        },
        iconStyle: {
            type: String,
            default: 'regular',
            required: false,
        }
    },
    data() {
        return {
            modal: {
                isOpen: false,
            },
            workspaceFilter: null,
            confirmAction: false,
            isNewWorkspace: false,
            selectedWorkspace: null,
        };
    },
    computed: {
        filteredWorkspaces() {

            if (this.workspaceFilter) {
                return this.workspaces.filter(workspace => workspace.name.indexOf(this.workspaceFilter) !== -1);
            }

            return this.workspaces;
        },

        drawerTogglerClasses() {
            let classes = [
                'assetCard__button',
            ];

            if (this.extraDrawerTogglerClasses) {
                classes = classes.concat(this.extraDrawerTogglerClasses);
            }

            return classes.join(' ');
        },
        drawerClasses() {
            let classes = [
                'downloadLinks drawer__links',
            ];

            if (this.extraDrawerClasses) {
                classes = classes.concat(this.extraDrawerClasses);
            }

            return classes.join(' ');
        },
    },
    methods: {
        getTruncatedName(workspace) {
            if (workspace.name.length > 15) {
                return workspace.name.substring(0, 15) + '...';
            }

            return workspace.name;
        },
        confirmNewWorkspace() {
            this.isNewWorkspace = true;
            this.confirmAction = true;
        },
        confirmAddToWorkspace(workspace) {
            this.selectedWorkspace = workspace;
            this.isNewWorkspace = false;
            this.confirmAction = true;
        },
        createNewWorkspace(name) {
            this.$store.dispatch('user/createWorkspace', {
                name: this.workspaceFilter,
                asset_ids: [this.asset.asset_id],
            })
            .then(() => {
                this.$store.dispatch(
                    'addNotification',
                    new Notification(`Workspace "${this.workspaceFilter}" has been created.`),
                    { root: true }
                );
            })
            .finally(() => {
                this.$store.dispatch('user/getWorkspaces');
                this.workspaceFilter = null;
                this.confirmAction = false;
                this.modal.isOpen = false;
            });
        },
        addAssetToWorkspace(workspace) {
            this.isNewWorkspace = false;
            this.confirmAction = true;

            this.$store.dispatch('user/addAssetsToWorkspace', {
                assets: [this.asset],
                workspace: this.selectedWorkspace
            }).then(response => {
                console.log(response);
            }).finally(() => {
                this.selectedWorkspace = null;
                this.confirmAction = false;
                this.workspaceFilter = null;
                this.modal.isOpen = false;
            });
        },

        toggleModal() {
            this.modal.isOpen = !this.modal.isOpen;
        },
        closeModal() {
            if (!this.modal.isOpen) {
                return;
            }

            this.modal.isOpen = false;
        },
        openModal() {
            if (this.modal.isOpen) {
                return;
            }

            this.modal.isOpen = true;
        },
        //
    }
};
</script>

<style>

</style>
