<template>
    <div v-if="type === 'assetDetails'" class="asset__preview">
        <figure class="asset__wrap">
            <video width="100%" height="300" controls preload="none" :poster="thumbnail" v-if="hasVideoUrl">
                <source :src="videoUrl" :type="videoType">
                Your browser does not support the video tag.
            </video>
            <div v-else>
                <modal
                    :visible.sync="showModal"
                    :content-classes="['w-2/3', 'text-center']"
                    :transparent="true"
                    :overflow-scroll="false"
                    v-if="!hasVideoUrl"
                >
                    <div :style="`background-color:${thumbnailBackgroundColor}`">
                        <img class="asset__enlarged" :src="fullSize">
                    </div>
                </modal>
                <img
                    @click="showModal = true"
                    :class="thumbnailClasses"
                    :src="thumbnail"
                    :alt="thumbnailAltText"
                    width="100%"
                    :style="`background-color:${thumbnailBackgroundColor}`"
                />
            </div>
            <figcaption class="assetDetails__metaValue -caption">
                <span v-if="asset.last_modified">
                    Last updated on {{ asset.last_modified }}
                </span>
                <span v-else>
                    Created on {{ asset.date_created }}
                </span>
            </figcaption>
        </figure>
    </div>

    <div v-else>
        <video width="276" height="200" controls preload="none" :poster="thumbnail" v-if="hasVideoUrl">
            <source :src="videoUrl" :type="videoType">
            Your browser does not support the video tag.
        </video>
        <div v-else class="assetThumbnail">
            <img
                v-lazy="thumbnail"
                :class="thumbnailClasses"
                @click="showModal = true"
            >
            <modal
                :visible.sync="showModal"
                :content-classes="['assetThumbnail__cardImg']"
                :transparent="true"
                :overflow-scroll="false"
                v-if="!hasVideoUrl"
            >
                <div :style="`background-color:${thumbnailBackgroundColor}`">
                    <img :src="fullSize">
                </div>
            </modal>
        </div>
    </div>
</template>


<script>
import Modal from 'Components/Modal';
import { fullSizeImage, isZipped } from '@/utilities/assetUtilities.js';

export default {
    components: {
        Modal,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        type: {
            required: false,
            type: String,
            default: 'assetCard',
        },
    },
    data: () => ({
        showModal: false,
    }),

    computed: {
        thumbnail() {
            let url = this.videoPreviewUrl;
            
            if (!url) {
                url = this.asset.asset_thumbnail_url;
            }
           
            if (this.type === 'assetDetails') {
                if (!this.hasVideoUrl && this.asset.files) {
                    url = this.bestAvailablePreview(this.asset.files);
                }
            }

            return url;
        },
        videoPreviewUrl() {
            if (this.hasVideoUrl) {
                let temp = this.asset.files.filter(f => f.type == 'video_preview');

                if (temp.length) {
                    return temp[0].url;
                }
            }

            return '';
        },
        videoUrl() {
            if (this.hasVideoUrl) {
                return this.asset.video_url;
            }
            
            return '';
        },
        videoType() {
            if (this.hasVideoUrl) {
                if ('webm' == this.asset.extension) {
                    return 'video/webm';
                }
                if ('ogv' == this.asset.extension) {
                    return 'video/ogg';
                }
                return 'video/mp4';
            }

            return '';
        },
        fullSize() {
            return fullSizeImage(this.asset);
        },
        hasVideoUrl() {
            return this.asset.video_url !== false && this.asset.video_url !== null;
        },
        thumbnailClasses() {
            let classes = [];

            if (this.type === 'assetCard') {
                classes.push('assetThumbnail__img');
                classes.push(this.asset.image_orientation);
            }

            if (this.type === 'assetDetails') {
                classes.push(
                    'assetThumbnail asset__enlarge',
                );

                if (this.hasVideoUrl) {
                    classes.push('asset__videoPreview');
                }
            }

            return classes.join(' ');
        },
        thumbnailAltText() {
            return this.asset.filename;
        },

        thumbnailBackgroundColor() {
            return this.asset.logo_background_filler || 'transparent';
        },
    },

    methods: {
        bestAvailablePreview(files) {
            const sizes = ['logo_png', 'logo_jpg', 'large', 'medium', 'low'];

            for (var i = 0; i < sizes.length; i++) {
                const match = files.find((file) => file.type === sizes[i]);

                if (match && match.url) {
                    if (! isZipped(match.url)) {
                        return match.url;
                    }
                }
            }

            return this.asset.asset_thumbnail_url;
        },
    },
};
</script>
