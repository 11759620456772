
const workspace = {
    namespaced: true,

    state: {
        workspaceSelectedAssets: [],
    },

    actions: {
        setSelectedAssets({ commit }, assets) {
            commit('setWorksapceSelectedAssets', assets);
        },

        getSelectedAssets({ state }) {
            return state.workspaceSelectedAssets;
        }
    },

    mutations: {
        setWorksapceSelectedAssets(state, assets) {
            state.workspaceSelectedAssets = assets;
        }
    },
};

export default workspace;
