<template>
    <nav role="navigation" class="asset__nav">
        <ul class="asset__tabList">
            <li class="asset__tab">
                <add-to-workspace :asset="asset" :workspaces="$store.state.user.workspaces" />
            </li>
            <li class="asset__tab">
                <asset-downloads :asset="asset" :extraDrawerClasses="['asset__tabDropdown']" />
            </li>
        </ul>
    </nav>
</template>

<script>
import AssetDownloads from '../AssetDownloads';
import AddToWorkspace from '../AddToWorkspace';

export default {
    components: {
        AssetDownloads,
        AddToWorkspace,
    },

    props: {
        asset: {
            type: Object,
            required: true,
        },
    },

    data: () => ({}),

    computed: {
        assetLinks() {
            if (this.asset.links) {
                if (this.isTemplate) {
                    if (this.asset.links.downloads.high) {
                        return {
                            'high': this.asset.links.downloads.high,
                        };
                    }

                    return {};
                }
                return this.asset.links.downloads;
            }

            return {};
        },
        isTemplate() {
            if (this.asset.asset_type && this.asset.asset_type.name == 'Template') {
                return true;
            }
            return false;
        },
        hasDownloadLinks() {
            return Object.keys(this.assetLinks).length > 0;
        },
    },
};
</script>
