<template>
<div
    class="drawer"
    :data-drawer-id="drawerId"
>
    <div class="drawer__controls">
        <slot
            name="trigger"
            :toggleDrawer="toggleDrawer"
            :openDrawer="openDrawer"
            :closeDrawer="closeDrawer"
        />
    </div>

    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
        <div
            v-show="drawer.isOpen"
            class="drawer__contents"
        >
            <slot
                name="drawer"
            />
        </div>
    </transition>
</div>
</template>

<script>
import {v4 as uuid} from 'uuid';

export default {
    data() {
        return {
            drawer: {
                isOpen: false,
                listener: null,
            },
        };
    },
    computed: {
        drawerId() {
            return `drawer-${uuid()}`;
        }
    },
    mounted() {
        //
    },
    methods: {
        toggleDrawer() {
            this.drawer.isOpen = !this.drawer.isOpen;

            if (this.drawer.isOpen) {
                this.addListeners();
            } else {
                this.removeListeners();
            }
        },
        closeDrawer(event) {
            if (!this.drawer.isOpen) {
                return;
            }

            if (event.target.closest(`div[data-drawer-id='${this.drawerId}']`)) {
                return;
            }

            this.drawer.isOpen = false;
            this.removeListeners();
        },
        openDrawer() {
            if (this.drawer.isOpen) {
                return;
            }

            this.drawer.isOpen = true;
            this.addListeners();
        },
        bodyListener(event) {
            if (event.key === 'Escape') {
                this.closeDrawer(event);
            }
        },
        addListeners() {
            if (!this.listener) {
                document.body.addEventListener('click', this.closeDrawer, true);
                document.body.addEventListener('keyup', this.bodyListener, true);
                this.listener = true;
            }
        },
        removeListeners() {
            if (this.listener) {
                document.body.removeEventListener('click', this.closeDrawer);
                document.body.removeEventListener('keyup', this.bodyListener);
                this.listener = false;
            }
        }
    }
};
</script>
