<template>
<div class="overflow-hidden">
    <div class="recentSearches__head">
        <button
            :class="tabClasses('recent')"
            @click="showRecent()"
        >
            Recent
        </button>

        <button
            :class="tabClasses('stored')"
            @click="showStored()"
        >
            Saved
        </button>
    </div>

    <ul
        v-if="viewRecent"
        class="recentSearches__list"
    >
        <li
            v-for="recentSearch in recentSearches"
            :key="recentSearch.recent_search_id"
            class="recentSearches__listItem"
        >
            <a
                :href="recentSearch.link"
                class="link mb-1"
                v-text="recentSearch.name"
            />
        </li>
        <li class="recentSearches__listItem" v-if="!recentSearches.length">
            No recent searches found
        </li>
    </ul>
    <ul
        v-if="viewStored"
        class="recentSearches__list"
    >
        <li class="recentSearches__listItem">
            <input
                v-model="storedSearchName"
                type="text"
                class="recentSearches__input"
                placeholder="Find stored search..."
                @input="fetchStoredSearches"
            >
        </li>

        <li class="recentSearches__listItem" v-if="!storedSearches.length">
            No stored searches found
        </li>

        <li
            v-for="storedSearch in storedSearches"
            :key="storedSearch.stored_search_id"
            class="recentSearches__listItem"
        >
            <a
                :href="storedSearch.link"
                class="link"
                v-text="storedSearch.name"
            />
        </li>
    </ul>

    <div
        v-if="viewStored && storedSearches.length && storedSearchMeta.pagination"
        class="recentSearches__foot"
    >
        <button
            :disabled="!storedSearchMeta.pagination.links.previous"
            @click="fetchPreviousStoredSearches"
        >
            <i class="far fa-chevron-left" />
            Previous
        </button>
        <button
            :disabled="!storedSearchMeta.pagination.links.next"
            @click="fetchNextStoredSearches"
        >
            Next
            <i class="far fa-chevron-right" />
        </button>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import endpoints from 'Root/endpoints';
import { debounce } from 'lodash';

export default {
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            viewRecent: true,
            viewStored: false,
            recentSearches: [],
            storedSearches: [],
            storedSearchMeta: {},
            storedSearchName: null,
        };
    },
    mounted() {
        this.fetchRecentSearches();
    },
    methods: {

        showRecent() {
            this.viewStored = false;
            this.viewRecent = true;
        },
        showStored() {
            if (!this.storedSearches.length) {
                this.fetchStoredSearches();
            }

            this.viewRecent = false;
            this.viewStored = true;
        },
        tabClasses(type) {
            let classes = [
                'p-1',
                'cursor-pointer',
            ];

            if (type === 'recent' && this.viewRecent) {
                classes.push('-active');
            }

            if (type === 'stored' && this.viewStored) {
                classes.push('-active');
            }

            return classes.join(' ');
        },

        fetchRecentSearches() {
            axios.get(endpoints.SEARCHES_RECENT_LIST, { params: { limit: 10 } })
                .then(response => {
                    this.recentSearches = response.data.data;
                }).catch(error => {
                    console.error(error);
                });
        },
        fetchStoredSearches: debounce(
            function () {
                let params = {
                    page: 1,
                    limit: 10,
                };

                if (this.storedSearchName) {
                    params.name = this.storedSearchName;
                }
                axios.get(endpoints.SEARCHES_STORED_LIST, { params })
                    .then(response => {
                        this.storedSearches = response.data.data;
                        this.storedSearchMeta = response.data.meta;
                    }).catch(error => {
                        console.error(error);
                    });
            },
            300
        ),
        fetchNextStoredSearches() {
            if (!this.storedSearchMeta.pagination) {
                return false;
            }

            axios.get(this.storedSearchMeta.pagination.links.next)
                .then(response => {
                    this.storedSearches = response.data.data;
                    this.storedSearchMeta = response.data.meta;
                }).catch(error => {
                    console.error(error);
                });
        },
        fetchPreviousStoredSearches() {
            if (!this.storedSearchMeta.pagination) {
                return false;
            }

            axios.get(this.storedSearchMeta.pagination.links.previous)
                .then(response => {
                    this.storedSearches = response.data.data;
                    this.storedSearchMeta = response.data.meta;
                }).catch(error => {
                    console.error(error);
                });
        }
    },
};
</script>

<style>

</style>
