<template>
<section
    class="assetSearch"
>
    <div class="assetSearch__container">
        <div class="assetSearch__asset-lookup container">
            <div class="assetSearch__panel">
                <div class="assetSearch__filters">
                    <div class="assetSearch__filter">
                        <search-input
                            id="asset_type_Ids"
                            v-model="assetTypes"
                            :endpoint="endpoints.ASSET_TYPES_LIST"
                            label="Asset Type"
                            filter-by="name"
                            selected-display-key="name"
                            search-display-key="name"
                            label-plural="Asset Types"
                            placeholder="Add asset types to your search"
                            @input="search"
                        />
                    </div>

                    <div class="assetSearch__filter">
                        <search-keyword-input
                            id="keywords"
                            v-model="keywords"
                            label="Keyword"
                            label-plural="Properties"
                            placeholder="Add keywords to your search"
                            :live-search="true"
                            search-param="search"
                            @input="search"
                        />
                    </div>
                    <div class="assetSearch__filter">
                        <search-input
                            id="brand_ids"
                            v-model="brands"
                            :endpoint="endpoints.BRANDS_LIST"
                            label="Brand"
                            filter-by="name"
                            selected-display-key="name"
                            search-display-key="name"
                            label-plural="Brands"
                            placeholder="Add brands to your search"
                            @input="search"
                        />
                    </div>

                    <div class="assetSearch__filter">
                        <div class="searchInput">
                            <search-input
                                id="property_ids"
                                v-model="properties"
                                :endpoint="endpoints.PROPERTIES_LIST"
                                label="Property"
                                filter-by="name"
                                selected-display-key="name"
                                search-display-key="name"
                                :option-text-transform="property => `${property.marsha_code} - ${property.name}`"
                                :selected-text-transform="formattedPropertyName"
                                label-plural="Properties"
                                placeholder="Add properties to your search"
                                :live-search="true"
                                search-param="search"
                                @input="search"
                                search-help-text="Please enter MARSHA, property name or SPG pin"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="assetSearch__actions">
                <div class="assetSearch__recent">
                    <drawer>
                        <template v-slot:trigger="{ toggleDrawer }">
                            <button
                                class="assetSearch__button button -outline"
                                @click="toggleDrawer"
                                @keyup.esc="toggleDrawer"
                            >
                                Recent and Saved Searches <span class="caret"></span>
                            </button>
                        </template>

                        <template
                            v-slot:drawer
                            class=""
                        >
                            <recent-stored-searches :is-admin="isAdmin" />
                        </template>
                    </drawer>
                </div>

                <div v-if="liveSearch" class="assetSearch__form">
                    <a
                        href="/search/"
                        class="assetSearch__clear"
                    >
                        Clear Search
                    </a>

                    <a
                        href="/search"
                        class="button"
                        @click.prevent="search"
                    >Search</a>
                </div>

                <form
                    v-if="!liveSearch"
                    method="GET"
                    action="/search"
                    class="assetSearch__form"
                >
                    <input
                        v-for="(property, i) in properties"
                        :key="property.property_id"
                        type="hidden"
                        :name="`property_ids[${i}]`"
                        :value="property.property_id"
                    >

                    <input
                        v-for="(brand, i) in brands"
                        :key="brand.brand_id"
                        type="hidden"
                        :name="`brand_ids[${i}]`"
                        :value="brand.brand_id"
                    >

                    <input
                        v-for="(assetType, i) in assetTypes"
                        :key="assetType.asset_type_id"
                        type="hidden"
                        :name="`asset_type_ids[${i}]`"
                        :value="assetType.asset_type_id"
                    >

                    <input
                        v-for="(keyword, i) in keywords"
                        :key="i"
                        type="hidden"
                        :name="`keywords[${i}]`"
                        :value="keyword"
                    >
                    <div class="assetSearch__advanced">
                        <a
                            href="/search/"
                            class="button -outline"
                        >
                            Advanced Search
                        </a>
                    </div>

                    <div class="assetSearch__submit">
                        <button
                            type="submit"
                            class="button"
                        >
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Drawer from '../Drawer';
import SearchInput from '../SearchInput';
import SearchKeywordInput from '../SearchKeywordInput';
import RecentStoredSearches from '@/RecentStoredSearches';
import store from 'Stores/Frontend';
import endpoints from 'Root/endpoints';
import {
    SET_SEARCH_PROPERTIES,
    SET_SEARCH_BRANDS,
    SET_SEARCH_KEYWORDS,
    SET_SEARCH_ASSET_TYPES,
    SET_SEARCH_PAGE,
} from 'Stores/Frontend/mutation-types';

export default {
    store,
    components: {
        Drawer,
        RecentStoredSearches,
        SearchInput,
        SearchKeywordInput
    },
    props: {
        /**
         * Whether or not to perform the search via ajax or send a request to /search directly
         */
        liveSearch: {
            type: Boolean,
            default: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        startingFilters: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            endpoints,
            recentSearches: [],
            savedSearches: [],
        };
    },
    mounted() {
        this.parseStartingFilters();
    },
    computed: {
        properties: {
            get() {
                return this.$store.state.search.query.properties;
            },
            set(properties) {
                this.$store.commit(SET_SEARCH_PROPERTIES, properties);
            }
        },
        brands: {
            get() {
                return this.$store.state.search.query.brands;
            },
            set(brands) {
                this.$store.commit(SET_SEARCH_BRANDS, brands);
            }
        },
        keywords: {
            get() {
                return this.$store.state.search.query.keywords;
            },
            set(keywords) {
                this.$store.commit(SET_SEARCH_KEYWORDS, keywords);
            }
        },
        assetTypes: {
            get() {
                return this.$store.state.search.query.asset_types;
            },
            set(assetTypes) {
                this.$store.commit(SET_SEARCH_ASSET_TYPES, assetTypes);
            }
        },
        inputPropertyIds() {
            return this.properties.map(property => {
                return property.property_id;
            }).join(',');
        },
        inputBrandIds() {
            return this.brands.map(brand => {
                return brand.brand_id;
            }).join(',');
        },
        inputAssetTypeIds() {
            return this.assetTypes.map(assetType => {
                return assetType.asset_type_id;
            }).join(',');
        },
        inputKeywords() {
            return this.keywords.map(keyword => {
                return keyword.phrase;
            }).join(',');
        },
    },
    methods: {
        search() {
            if (this.liveSearch) {
                this.$store.commit(SET_SEARCH_PAGE, 1);
                this.$store.dispatch('search');
            }
        },
        clearSearch() {
            this.search.query = {
                keywords: [],
                properties: [],
                assetTypes: [],
                brands: [],
            };
        },
        formattedPropertyName(property) {
            let formatted = property.marsha_code + ' - ' + property.name;

            if (formatted.length > 30) {
                formatted = property.marsha_code + ' - ' + property.name.substr(0, 25) + '...';
            }

            return formatted;
        },
        
        parseStartingFilters() {
            Object.keys(this.startingFilters).forEach((key) => {
                if (this[key] !== undefined) {
                    this[key] = this.startingFilters[key];
                }
            });
        },
    }
};
</script>
