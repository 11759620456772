<template>
<div class="assetCardFull">
    <div class="assetCardFull__body">
        <div class="assetCardFull__status">
            <div class="checkbox -assetCard">
                <input
                    v-if="isSelectable"
                    :id="`select-${asset.asset_id}`"
                    v-model="model"
                    :name="`selected-${asset.asset_id}`"
                    type="checkbox"
                    class="checkbox__input"
                    @click="updateSelected($event)"
                >
                <label
                    class="checkbox__label"
                    :for="`select-${asset.asset_id}`"
                    v-text="selectLabel"
                />
                <span
                    :class="getStatusClasses()"
                    v-text="statusText"
                />
            </div>
        </div>

        <div class="assetCardFull__detail">
            <div>
                <div class="asset__files">
                    <asset-thumbnail
                        :asset="asset"
                        type="assetDetails"
                        image-size="full"
                    />
                </div>
            </div>
            <div>
                <div class="assetCardFull__fileName">
                    <span class="assetCardFull__fileID" v-html="asset.filename" />
                    <span v-if="isAdmin && asset.links.edit">
                        | <a
                            class="assetCardFull__edit"
                            :href="asset.links.edit"
                        >Edit Asset</a>
                    </span>
                </div>

                <div class="assetCardFull__content">
                    <div class="assetCardFull__heading">
                        <div
                            v-if="asset.association === 'property'"
                            class="assetCardFull__eyebrow"
                            v-text="asset.property.brand.name"
                        />
                        <div
                            v-else
                            class="assetCardFull__eyebrow"
                            v-text="asset.brand.name"
                        />
                        <div
                            v-if="asset.association === 'property'"
                            class="assetCardFull__title"
                            v-text="asset.property.name"
                        />
                        <div
                            v-if="asset.caption"
                            class="assetCardFull__mustache"
                            v-text="asset.caption"
                        />
                    </div>

                    <div class="assetCardFull__footer">
                        <dl class="text-slate-light text-sm">
                            <div class="assetCardFull__date" v-if="asset.subject">
                                <dt>Subject</dt>
                                <dd>{{ asset.subject.name }}</dd>
                            </div>
                            <div class="assetCardFull__date" v-if="assetType === 'Template'">
                                <dt>Type</dt>
                                <dd>{{ templateType }}</dd>
                            </div>
                            <div class="assetCardFull__date" v-if="assetType == 'Logo'">
                                <dt>Color</dt>
                                <dd>{{ color }}</dd>
                            </div>
                            <div class="assetCardFull__date" v-if="asset.deactivation_reason">
                                <dt>Reason Deactivated</dt>
                                <dd>{{ asset.deactivation_reason }}</dd>
                            </div>
                            <div class="assetCardFull__date">
                                <dt>Date Created</dt>
                                <dd>{{ formattedDateCreated }}</dd>
                            </div>
                            <div class="assetCardFull__date">
                                <dt>Date Updated</dt>
                                <dd>{{ formattedDateUpdated }}</dd>
                            </div>
                            <div
                                v-if="likeable.show"
                                class="assetCardFull__switch"
                            >
                                <dt>Likes</dt>
                                <dd>
                                    <like-toggle
                                        :id="`like_toggle_${asset.asset_id}`"
                                        :asset-id="asset.asset_id"
                                        :count="likeable.count"
                                        :liked="likeable.liked"
                                        @input="toggleLiked()"
                                    />
                                </dd>
                            </div>
                            <div
                                v-if="isAdmin"
                                class="assetCardFull__switch"
                            >
                                <dt>Visible to API</dt>
                                <dd>
                                    <toggle-switch
                                        :id="`visible_to_api_${asset.asset_id}`"
                                        v-model="visibleToApi"
                                        name="visible_to_api"
                                        :input-value="true"
                                        :unchecked-value="false"
                                        @input="toggleApiVisibility()"
                                        type="assetCard"
                                    />
                                </dd>
                            </div>
                        </dl>
                        <slot name="footer-extras" />
                    </div>
                    
                    <div class="assetCardFull__actions">
                        <div v-if="showViewLink" class="assetCardFull__links">
                            <a
                                :href="asset.links.view"
                                class="assetCardFull__link"
                            >
                                View
                            </a><span class="assetCardFull__arrow"></span>
                        </div>
                        <asset-downloads
                            :asset="asset"
                            :extra-drawer-classes="['py-4']"
                            icon-style="light"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import AssetThumbnail from '@/AssetThumbnail';
import AssetDownloads from '@/AssetDetails/AssetDownloads';
import AssetMixins from '@/Mixins/AssetMixins';
import LikeToggle from '@/LikeToggle';
import ToggleSwitch from '@/ToggleSwitch';

export default {
    components: {
        AssetThumbnail,
        AssetDownloads,
        LikeToggle,
        ToggleSwitch
    },
    mixins: [
        AssetMixins,
    ],
    props: {
        asset: {
            required: true,
            type: Object,
        },
        selected: {
            required: false,
            type: Boolean,
        },
        isSelectable: {
            required: false,
            type: Boolean,
            default: true,
        },
        selectLabel: {
            required: false,
            type: String,
            default: '',
        },
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
        showViewLink: {
            required: false,
            type: Boolean,
            default: true,
        },
        likeable: {
            required: false,
            type: Object,
            default: function() {
                return {
                    show:false, 
                    count:0, 
                    liked:false
                }
            },
        },
    },
    data() {
        return {
            isSelected: false,
        };
    },
    computed: {
        liked: {
            get() {
                return this.selected;
            },
            set(isSelected) {
                this.isSelected = isSelected;
            }
        },
        model: {
            get() {
                return this.selected;
            },
            set(isSelected) {
                this.isSelected = isSelected;
            }
        },
    },
    methods: {
        toggleLiked(event) {
            let val = !this.likeable.liked;
            this.$emit('liked', val);
        },
        updateSelected(event) {
            this.$emit('input', event.target.checked);
        },
    }
};
</script>
