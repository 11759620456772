<template>
<label
    class="like-toggle"
    role="toggle"
    :for="id"
    @click="toggleValue()"
>
    <i :class="thumbClass" /> (<span v-text="count" />)
    <input
        :id="id"
        v-model="model"
        type="hidden"
        name="liked"
        value="true"
    >
</label>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },
        assetId: {
            required: true,
            type: [String,Number],
        },
        count: {
            required: false,
            type: Number,
            default: 0
        },
        liked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            // value: null,
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        thumbClass() {
            if (this.liked) {
                return 'fa fa-thumbs-up liked';
            }

            return 'fa fa-thumbs-up';
        }
    },
    methods: {
        toggleValue() {
            if (this.value) {
                this.model = false;
            } else {
                this.model = true;
            }
        }
    }
};
</script>

<style>
    .fa {
        font-size:1.1rem;
    }
    .like-toggle {
        cursor: pointer;
    }
    .liked {
        color: #33ABDE;
    }
</style>
