export function fullSizeImage(asset) {
    if (asset.files.length) {
        const sizes = ['logo_png', 'logo_jpg', 'medium', 'low', 'large', 'high']; // sorted to pull the large/high format last to limit page size as much as possible.

        for (var i = 0; i < sizes.length; i++) {
            if (isTiff(asset) && sizes[i] === 'high') {
                continue;
            }

            const match = asset.files.find((file) => file.type === sizes[i]);

            if (match && match.url) {
                if (!isZipped(match.url)) {
                    return match.url;
                }
            }
        }
    }

    return asset.asset_thumbnail_url;
}

export function isZipped(url) {
    const ext = url.split('.').pop();

    return ext === "zip";
}

export function isTiff(asset) {
    if (! asset.file_type) {
        return asset.extension
            ? asset.extension.toLowerCase() === 'tif'
            : false;
    }

    const { name } = asset.file_type;

    return name.toLowerCase() === 'tiff' || name.toLowerCase() === 'tif';
}