<template>
    <div class="moveToWorkspace">
        <drawer>
            <template v-slot:trigger="{ toggleDrawer }">
                <a
                    class="button"
                    @click="toggleDrawer"
                    @keyup.esc="toggleDrawer"
                >
                    Download Assets
                    <i class="link--icon --vertical far fa-fw fa-angle-down text-coral" />
                </a>
            </template>
    
            <template v-slot:drawer>    
                <ul>
                    <li
                        v-for="downloadType in downloadTypes"
                        :key="downloadType"
                        class="asset__tabItem"
                    >
                        <a
                            class="asset__tabLink"
                            @click="showOptionModal(downloadType.toLowerCase())"
                        >
                            <span>{{ downloadType }}</span>
                        </a>
                    </li>
                </ul>
            </template>
        </drawer>
        <modal
            v-if="showDownloadModal"
            :visible="showDownloadModal"
            :transparent="false"
        >
            <div class="workspace__modal">
                <div class="workspace__text">
                    <h3>
                        Please select the type of download.
                    </h3>
                </div>
                <div class="workspace__modal--close">
                    <a href="#" @click.prevent="closeOptionModal">
                        <i class="fa fa-times"></i>
                    </a>
                </div>
                <div class="workspace__modalActions">
                    <button
                        class="button --success"
                        @click="downloadAssets({
                            organized_directories: false,
                            media_only: true,
                        })"
                    >
                        Images Only
                    </button>
                    <button
                        class="button -outline"
                        @click="downloadAssets"
                    >
                        Images with Releases
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Drawer from '@/Drawer';
import Modal from '@/Modal';

export default {
    components: {
        Drawer,
        Modal,
    },
    props: {
        downloadLink: {
            type: String,
            required: true,
        },
        workspaceId: {
            type: [Number, String],
            required: true,
        },
    },
    data: () => ({
        showDownloadModal: false,
        downloadTypes: [
            'High',
            'Large',
            'Medium',
        ],
        downloadOptions: {
            file_type: 'high',
            all_files: false,
            organized_directories: true,
            media_only: false,
            force: true,
        },
    }),
    mounted() {
        // this.showDownloadModal = false;
    },

    computed: {
        selectedWorkspaceAssets() {
            return this.$store.state.workspace.workspaceSelectedAssets || [];
        },
    },

    methods: {
        showOptionModal(resolution) {
            this.downloadOptions.file_type = resolution;
            this.showDownloadModal = true;
        },

        closeOptionModal() {
            this.downloadOptions.file_type = 'high';
            this.showDownloadModal = false;
        },
        
        downloadAssets(options = {}) {
            this.showDownloadModal = false;
            const domain = window.location.hostname;
            const path = this.downloadLink.replace(new RegExp("^/"), "");;
            const url = path.includes(domain)
                ? new URL(path)
                : new URL(`https://${domain}/${path}`);

            const searchParams = url.searchParams;

            Object
                .keys(this.downloadOptions)
                .forEach((key) => {
                    const value = key in options
                        ? options[key]
                        : this.downloadOptions[key];

                    searchParams.set(key, value)
                });

            if (this.selectedWorkspaceAssets.length) {
                this.selectedWorkspaceAssets.forEach(asset => {
                    searchParams.append('asset_ids[]', asset.asset_id);
                });
            }

            window.open(url);

            return url;
        },
    }
};
</script>
