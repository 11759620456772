<template>
<div
    :id="`searchInput--${id}`"
    class="searchInputGroup relative text-sm"
>
    <label class="searchInput__label" :for="id">
        {{ label }}
    </label>

    <div
        :class="[
            'searchInput__optionsWrapper',
            viewOptions ? 'drop-shadow-bottom' : '',
        ]"
        ref="searchInputContainer"
    >
        <div class="searchInput__filterWrapper">
            <input
                :id="id"
                ref="filterInput"
                v-model="filter"
                type="text"
                :class="['searchInput__filter']"
                :placeholder="placeholder"
                autocomplete="false"
                @click="toggleOptions"
                @keyup.esc="toggleOptions"
                @keyup.enter="addKeyword(filter)"
            >
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <ul
                v-show="viewOptions"
                class="searchInput__options"
            >
                <li
                    v-if="!filter"
                    class="searchInput--option"
                >
                    Enter your keyword
                </li>

                <li
                    v-if="filter"
                    class="searchInput--option"
                    @click="addKeyword(filter)"
                >
                    Add "{{ filter }}" to keywords
                </li>

                <li
                    v-for="(option, i) in filteredOptions"
                    :key="i"
                >
                    <label
                        :class="[
                            'searchInput--option',
                            isSelected(option) ? '--selected' : '',
                        ]"
                    >
                        {{ optionTextTransform(option) }}
                        <input
                            v-model="selected"
                            type="checkbox"
                            class="sr-only"
                            :value="option"
                            @change="optionChosen()"
                        >
                    </label>
                </li>
            </ul>
        </transition>
    </div>

    <div
        v-if="selected.length"
        class="searchInput__selectedSearch selected"
    >
        <label
            v-for="(item, i) in selected"
            :key="i"
            class="pill"
        >
            <input
                v-model="selected"
                type="checkbox"
                :value="item"
                class="sr-only"
            >
            {{ item }}
            <span class="exit"></span>
        </label>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import { debounce } from 'lodash';
import SearchInputMixins from '@/Mixins/SearchInputMixins';

export default {
    components: {},
    mixins: [
        SearchInputMixins,
    ],
    props: {
        id: {
            required: true,
            type: String,
        },
        value: {
            required: true,
            default: () => [],
            type: Array
        },
        label: {
            required: true,
            type: String,
        },
        labelPlural: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
            default: null
        },
        optionTextTransform: {
            required: false,
            type: Function,
            default(option) {
                return option[this.searchDisplayKey];
            }
        },
    },
    data() {
        return {
            options: [],
            filter: null,
            internalValue: [],
            isLoading: false,
        };
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(selected) {
                this.internalValue = selected;
                this.$emit('input', this.internalValue);
                this.closeOptions();
            }
        },
        filteredOptions() {
            let filtered = this.options;

            if (this.filter) {
                return filtered.filter(option => {
                    return option[this.filterBy].toLowerCase().includes(this.filter.toLowerCase());
                });
            }

            return filtered;
        }
    },
    mounted() {
        if (this.endpoint && !this.liveSearch) {
            this.fetchOptions();
        }
    },
    methods: {
        fetchOptions() {
            if (this.endpoint) {
                axios.get(this.endpoint)
                    .then(response => {
                        this.options = response.data.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        searchOptions: debounce(function() {
            if (!this.filter) {
                this.options = [];
                return false;
            }

            let params = {};

            params[this.searchParam] = this.filter;

            this.isLoading = true;

            axios.get(this.endpoint, { params })
                .then(response => {
                    this.options = response.data.data;
                    this.openOptions();
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.isLoading = false;
                });
        }, 300),
        isSelected(option) {
            let filterBy = this.filterBy;
            return this.selected.find(selected => selected[filterBy] === option[filterBy]);
        },
        optionChosen() {
            // if (this.liveSearch) {
            //     this.closeOptions();
            //     this.filter = null;
            //     this.options = [];
            // }
        },
        addKeyword(keyword) {
            this.selected.push(keyword);
            this.filter = null;
            this.closeOptions();
        }
    }
};
</script>

<style>

</style>
