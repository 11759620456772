<template>
<div class="max-h-96 overflow-y-scroll">
    <table class="table -sticky">
        <thead class="">
            <tr>
                <th class="table--head">
                    Property/Brand Association
                </th>
                <th class="table--head">
                    Request Type
                </th>
                <th class="table--head">
                    Status
                </th>
            </tr>
        </thead>
        <tbody class="">
            <tr
                v-if="!workOrders.length"
                class="table--row"
            >
                <td
                    v-if="!isLoading"
                    class="table--cell"
                >
                    No work orders were found
                </td>
                <td
                    v-if="isLoading"
                    class="table--cell"
                >
                    <i class="far fa-fw fa-spinner fa-spin" />
                </td>
                <td class="table--cell"></td>
                <td class="table--cell"></td>
            </tr>

            <tr
                v-for="workOrder in workOrders"
                :key="workOrder.id"
                class="table--row"
            >
                <td
                    class="table--cell"
                    v-text="formatName(workOrder)"
                />
                <td
                    class="table--cell"
                    v-text="workOrder.request_type.data.name"
                />
                <td
                    class="table--cell"
                >
                    <span
                        :class="getButtonStatusClass(workOrder)"
                        v-text="workOrder.formatted_status"
                    />

                    <button
                        class="link float-right inline-block"
                        @click.prevent="showDetails(workOrder)"
                    >
                        Details
                    </button>
                </td>
            </tr>
        </tbody>

    </table>
    <modal
        v-if="detailView"
        :visible.sync="detailsVisible"
        :content-classes="['w-2/3']"
    >
        <div class="overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Work Order #{{ detailView.id }}
                    <div class="float-right">
                        <span
                            :class="getButtonStatusClass(detailView)"
                            v-text="detailView.formatted_status"
                        />
                    </div>
                </h3>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Submitted By
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {{ detailView.submittedby.data.full_name }}
                        </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Association
                        </dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span
                                v-if="detailView.brand_id"
                                v-text="detailView.brand.data.name"
                            />
                            <span
                                v-if="detailView.property_id"
                                v-text="detailView.property.data.name"
                            />
                        </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Request Type
                        </dt>
                        <dd
                            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                            v-text="detailView.request_type.data.name"
                        />
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Urgency
                        </dt>
                        <dd
                            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                            v-text="capitalize(detailView.urgency)"
                        />
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">
                            Notes
                        </dt>
                        <dd
                            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                            v-text="detailView.submitter_notes"
                        />
                    </div>
                </dl>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import endpoints from 'Root/endpoints';
import axios from 'axios';
import Modal from 'Components/Modal';
import capitalize from 'lodash/capitalize';
import { SET_USER_WORK_ORDERS }  from 'Stores/Frontend/mutation-types';
import { mapState } from 'vuex';

export default {
    components: {
        Modal,
    },
    props: {

    },
    data() {
        return {
            detailView: null,
            detailsVisible: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapState({
            workOrders: state => state.user.workOrders,
        })
    },
    mounted() {
        this.fetchWorkOrders();
    },
    methods: {
        capitalize,
        fetchWorkOrders() {
            if (this.workOrders.length > 0) {
                return;
            }

            this.isLoading = true;

            axios.get(
                endpoints.USER_WORK_ORDERS_LIST,
                {
                    params: {
                        status: [
                            'open',
                            'closed',
                        ],
                        includes: [
                            'property',
                            'brand',
                            'request_type',
                            'submittedby',
                            'notes',
                        ]
                    }
                }
            ).then(res => {
                this.$store.commit('user/' + SET_USER_WORK_ORDERS, res.data.data);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.isLoading = false;
            });
        },
        formatName(workOrder) {
            if (workOrder.property_id) {
                return `${workOrder.property.data.marsha_code} - ${workOrder.property.data.name}`;
            }
        },
        getButtonStatusClass(workOrder) {
            let classes = [
                'pill',
                '--rounded',
                '--small'
            ];

            if (workOrder.status === 'open') {
                classes.push('--primary');
            }

            if (workOrder.status === 'on_hold') {
                classes.push('--yellow');
            }

            if (workOrder.status === 'closed') {
                classes.push('--success');
            }

            if (workOrder.status === 'pending') {
                classes.push('--coral');
            }

            if (workOrder.status === 'deleted') {
                classes.push('--danger');
            }

            return classes.join(' ');
        },
        showDetails(workOrder) {
            this.detailView = workOrder;
            this.detailsVisible = true;
        }
    }
};
</script>

<style>

</style>
