<template>
    <div :class="['searchResults__controls',]">
        <div class="searchResults__actions">

            <div class="checkbox searchResults__checkbox">
                <input
                    type="checkbox"
                    :checked="allSelected"
                    @input="$emit('select-all', $event)"
                    class="checkbox__input"
                >
                <label class="checkbox__label">
                    Select All
                </label>
            </div>

            <toggle-switch
                id="activeAssetsOnly"
                v-model="activeAssetsOnly"
                name="activeAssetsOnly"
                :unchecked-value="{id: 0, name: 'All', value: 'all'}"
                :input-value="{id: 5, name: 'Active', value: 'active'}"
                text="Active assets only"
            />
        </div>
        <div class="searchResults__info">
            <span
                v-if="total"
                class="searchResults__resultsNumber"
            >
                {{ total }} matching results
            </span>

            <div class="searchResults__pagination">
                <label class="searchResults__limit label">
                    Results per page
                    <v-select :options="options" v-model="limit"></v-select>
                </label>
            </div>

            <paginate
                v-if="pages"
                v-model="currentPage"
                container-class="pagination"
                :page-count="pages"
                :click-handler="changePage"
                :page-range="5"
                page-class="pagination__item"
                active-class="-active"
                disabled-class="-disabled"
                page-link-class="pagination__link"
                break-view-class="pagination__breakView"
                prev-text="<i class='pagination__icon fa fa-chevron-left' />"
                next-text="<i class='pagination__icon fa fa-chevron-right' />"
            >

                <template v-slot:breakViewContent>
                    <div class="">
                        <input
                            v-if="viewGoToPage"
                            ref="goToPageInput"
                            v-model.lazy="currentPage"
                            type="text"
                            class="pagination__goToPage"
                            @blur="viewGoToPage = false"
                        >
                        <span
                            class="cursor-pointer"
                            @click="showGoToPage()"
                        >...</span>
                    </div>
                </template>
            </paginate>
        </div>
    </div>
</template>

<script>
import ToggleSwitch from '@/ToggleSwitch';
import Paginate from 'vuejs-paginate';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

import {
    SET_SEARCH_LIMIT,
    SET_SEARCH_PAGE,
    SET_SEARCH_STATUS,
} from 'Stores/Frontend/mutation-types';

export default {
    components: {
        ToggleSwitch,
        Paginate,
        vSelect
    },
    props: {
        allSelected: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            selected: [],
            originalStatus: null,
            viewGoToPage: false,
            options: [24, 48, 72, 96]
        };
    },
    computed: {
        isLoading() {
            return this.$store.state.search.isLoading;
        },
        limit: {
            get() {
                return this.$store.state.search.query.limit;
            },
            set(value) {
                this.$store.commit(SET_SEARCH_LIMIT, value);
                this.$store.dispatch('search');
            }
        },
        pages: {
            get() {
                return this.$store.state.search.meta.pagination.total_pages;
            },
        },
        currentPage: {
            get() {
                return this.$store.state.search.meta.pagination.current_page;
            },
            set(value) {
                this.$store.commit(SET_SEARCH_PAGE, value);
                this.$store.dispatch('search');
            }
        },
        total: {
            get() {
                return this.$store.state.search.meta.pagination.total;
            }
        },
        activeAssetsOnly: {
            get() {
                return this.$store.state.search.query.status.value === 'active';
            },
            set(value) {
                this.$store.commit(SET_SEARCH_STATUS, value);
                this.$store.dispatch('search');
            },
        },
    },
    methods: {
        changePage(page) {
            this.$store.commit(SET_SEARCH_PAGE, page);
            this.$store.dispatch('search');

            this.viewGoToPage = false;

            let assetSearch = document.querySelector('.assetSearch');

            if (assetSearch) {
                assetSearch.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
        showGoToPage() {
            this.viewGoToPage = true;

            this.$nextTick(() => {
                this.$refs.goToPageInput.focus();
            });
        }
    }
};
</script>

<style>

</style>
