var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"searchInputGroup relative text-sm",attrs:{"id":`searchInput--${_vm.id}`}},[_c('label',{staticClass:"searchInput__label",attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n    ")]),_vm._v(" "),_c('div',{ref:"searchInputContainer",class:[
            'searchInput__optionsWrapper',
            _vm.viewOptions ? 'drop-shadow-bottom' : '',
        ]},[_c('div',{staticClass:"searchInput__filterWrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],ref:"filterInput",class:['searchInput__filter'],attrs:{"id":_vm.id,"type":"text","placeholder":_vm.placeholder,"autocomplete":"false"},domProps:{"value":(_vm.filter)},on:{"click":_vm.toggleOptions,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.toggleOptions.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addKeyword(_vm.filter)}],"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})]),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"transition duration-100 ease-out","enter-class":"transform scale-95 opacity-0","enter-to-class":"transform scale-100 opacity-100","leave-active-class":"transition duration-75 ease-in","leave-class":"transform scale-100 opacity-100","leave-to-class":"transform scale-95 opacity-0"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewOptions),expression:"viewOptions"}],staticClass:"searchInput__options"},[(!_vm.filter)?_c('li',{staticClass:"searchInput--option"},[_vm._v("\n                    Enter your keyword\n                ")]):_vm._e(),_vm._v(" "),(_vm.filter)?_c('li',{staticClass:"searchInput--option",on:{"click":function($event){return _vm.addKeyword(_vm.filter)}}},[_vm._v("\n                    Add \""+_vm._s(_vm.filter)+"\" to keywords\n                ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredOptions),function(option,i){return _c('li',{key:i},[_c('label',{class:[
                            'searchInput--option',
                            _vm.isSelected(option) ? '--selected' : '',
                        ]},[_vm._v("\n                        "+_vm._s(_vm.optionTextTransform(option))+"\n                        "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"sr-only",attrs:{"type":"checkbox"},domProps:{"value":option,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,option)>-1:(_vm.selected)},on:{"change":[function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}},function($event){return _vm.optionChosen()}]}})])])})],2)])],1),_vm._v(" "),(_vm.selected.length)?_c('div',{staticClass:"searchInput__selectedSearch selected"},_vm._l((_vm.selected),function(item,i){return _c('label',{key:i,staticClass:"pill"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"sr-only",attrs:{"type":"checkbox"},domProps:{"value":item,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,item)>-1:(_vm.selected)},on:{"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}}),_vm._v("\n            "+_vm._s(item)+"\n            "),_c('span',{staticClass:"exit"})])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }