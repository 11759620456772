<template>
    <div :class="cssClass">
        <select :id="id" v-model="selected" class="filter" :multiple="multiple">
            <option value="" v-text="placeholder" class="disabled" />
            <option :value="optVal(option)" v-text="optText(option)" v-for="option in options" />
        </select>

        <div v-if="multiple" class="searchInput__selectedSearch">
            <label v-for="val in selections" class="pill" @click="remove(val)">
                <span v-text="textVal(val)" />
                <span class="exit" />
            </label>
        </div>
    </div>
</template>

<script>    
    export default {
        props: {
            value: {
                type: [String,Array],
                required: true,
            },
            id: {
                type: String,
                required: true,
            },
            cssClass: {
                type: String,
                required: false,
                default: '',
            },
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
            options: {
                type: Array,
                required: true,
            },
            valueKey: {
                type: String,
                required: false,
                default: '',
            },
            displayKey: {
                type: String,
                required: false,
                default: '',
            },
            multiple: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data: () => ({
            loading: false,
        }),
        computed: {
            selected: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', this.filterEmpty(value));
                }
            },
            selections() {
                return this.filterEmpty(this.value);
            }
        },
        methods: {
            optVal(opt) {
                if (this.valueKey) {
                    return opt[this.valueKey];
                }
                return opt;
            },
            optText(opt) {
                if (this.displayKey) {
                    return opt[this.displayKey];
                }
                return opt;
            },
            textVal(value) {
                if (this.displayKey) {
                    if (Array.isArray(this.options)) {
                        let textVal = '';
                        let vk = this.valueKey;
                        let dk = this.displayKey;
                        this.options.forEach(opt => {
                            if (opt[vk] == value) {
                                textVal = opt[dk];
                            }
                        });

                        return textVal;
                    }
                }
                return value;
            },
            filterEmpty(value) {
                if (Array.isArray(value)) {
                    return value.filter(v => { return v.length > 0; });
                } else {
                    return value;
                }
            },
            remove(val) {
                let temp = this.value;
                let index = temp.indexOf(val);
                if (index > -1) {
                    temp.splice(index, 1);
                }

                this.$emit('input', this.filterEmpty(temp));

                if (temp.length == 0) {
                    let element = document.getElementById(this.id);
                    element.selectedIndex = 0;
                    element.scrollTop = 0;
                } else {
                    // TODO: find the last selected option and set selectedIndex to center on it.
                }
            },
        }
    };
</script>
    
<style>
select.filter {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 1px solid #CCC;
    border-radius: 0.25rem;
    height: auto;
    max-height: 2.4em; /* Set max height to match normal select input */
    overflow-y: hidden;
}
select.filter:focus {
    overflow-y: scroll;
    max-height: 9em; /* Set max height to match normal select input */
}
select.filter::after {
    content: '\25BC'; /* Unicode for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}
option.disabled {
    color:#999;
    padding:.1em .2em .4em .2em;
}
</style>