var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"linkNav__links"},_vm._l((_vm.links),function(link){return _c('li',{key:link.url,staticClass:"linkNav__item"},[_c('a',{class:[
                'linkNav__link',
                _vm.isActive(link) ? '--active' : '',
            ],attrs:{"href":link.url}},[_c('span',{class:[
                    `-${link.icon}`,
                    'linkNav__icon',
                ]}),_vm._v(" "),_c('span',{class:[
                    'linkNav__text',
                    'inline-block',
                    'overflow-hidden',
                    _vm.isOpen ? 'ml-5.5' : '',
                    _vm.isOpen ? 'w-full' : 'w-0',
                ],domProps:{"textContent":_vm._s(link.text)}})])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }