<template>
<li class="card">
    <div
        class="card__imageWrapper"
    >
        <img
            class="card__image"
            :src="getThumbnailUrl(workspace.thumbnail)"
            alt="Hotel Pool Image"
        >
    </div>
    <div class="card__contentWrapper">
        <h3
            class="card__title"
            v-text="truncatedName"
        />
        <p
            class="card__meta"
            v-text="workspace.asset_count_inflection"
        />
        <div class="card__cta">
            <a
                :href="workspace.links.view"
                class="card__ctaLink"
            >
                View All
            </a>
            <span class="arrow"></span>
        </div>
    </div>
    <div
        v-if="$slots.actions"
        class="card--actions"
    >
        <slot name="actions" />
    </div>
</li>
</template>

<script>
    export default {
        props: {
            workspace: {
                required: true,
                type: Object,
            },
        },
        data() {
            return {};
        },
        computed: {
            truncatedName() {
                if (this.workspace.name.length > 30) {
                    return this.workspace.name.substring(0, 30) + '...';
                }

                return this.workspace.name;
            }
        },
        methods: {
            getThumbnailUrl(thumbnail) {
                if (! thumbnail) {
                    return '/sup/img/assets/no_200x200.gif';
                };

                return thumbnail.asset_thumbnail_url;
            },
            getBackgroundImageStyle(workspace) {
                if (workspace.thumbnail.data) {
                    return `background-image: url('${workspace.thumbnail.asset_thumbnail_url}');`;
                }
            },
        }
    };
</script>

<style>

</style>
