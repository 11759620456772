<script>
export default {
    props: {
        assetId: {
            required: true,
            type: Number,
        },
        workspaces: {
            required: true,
            type: Array,
        },
        action: {
            required: true,
            type: String,
        },
        method: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            type: 'new',
        };
    },
};
</script>

<template>
<form
    class="addToWorkspace row"
    :action="action"
    :method="method"
>
    <div class="column two">
        Add To:
    </div>
    <div class="column ten">
        <div class="addToWorkspace__switcher">
            <label class="addToWorkspace__switcherLabel">
                <input
                    class="addToWorkspace__radio"
                    type="radio"
                    v-model="type"
                    value="new"
                />
                New Workspace
            </label>

            <label class="addToWorkspace__switcherLabel">
                <input
                    class="addToWorkspace__radio"
                    type="radio"
                    v-model="type"
                    value="existing"
                />
                Existing Workspace
            </label>
        </div>
        <div class="addToWorkspace__workspace">
            <div
                v-if="type === 'existing'"
                class="addToWorkspace__existing"
            >
                <label class="addToWorkspace__existingLabel">
                    Select
                    <select name="workspace_id">
                        <option
                            v-for="workspace in workspaces"
                            :key="workspace.workspace_id"
                            :value="workspace.workspace_id"
                        >
                            {{ workspace.name }}
                        </option>
                    </select>
                </label>
            </div>

            <div
                v-if="type === 'new'"
                class="addToWorkspace__new"
            >
                <label class="addToWorkspace__newLabel">
                    Name
                    <input type="text" name="name" placeholder="Enter Name" />
                </label>
            </div>

            <input type="hidden" name="asset_ids[]" :value="assetId">
        </div>
    </div>
    <div class="addToWorkspace__actions">
        <input
            class="addToWorkspace__submit button"
            type="submit"
            value="Add Asset"
        >
    </div>
</form>
</template>

<style lang="scss">
@import '../../../../styles/frontend/colors';

.addToWorkspace {

    &__switcher {
        display: flex;
        justify-content: space-between;

        &Label {
            font-size: .9em;
        }
    }

    &__existing {
        margin-top: 1em;
        &Label,
        select {
            font-size: .9em;
        }
    }

    &__new {
        margin-top: 1em;

        &Label,
        input {
            font-size: .9em;
        }
    }

    input[type="radio"] {
        margin: 0;
        float: none;
    }

    &__actions {
        text-align: right;
    }

    &__submit {
        margin: 1em 0;
        float: right;
        background-color: transparent;
        color: $vistana-blue;
        border: 1px solid $vistana-blue;
        padding: .5em 1em;
    }
}
</style>
