<template>
<label class="radio">
    <span class="radio--input">
        <input
            :name="name"
            type="radio"
            :value="content"
            :checked="isChecked"
            @input="$emit('input', content)"
        >
        <span class="radio--control" />
    </span>
    <span
        class="radio--label"
        v-text="label"
    />
</label>
</template>

<script>
export default {
    props: {
        label: {
            required: true,
            type: String
        },
        name: {
            required: true,
            type: String
        },
        value: {
            required: true,
            type: [String, Object]
        },
        content: {
            required: true,
            type: [String, Object]
        },
        compare: {
            required: false,
            type: String,
            default: null
        }
    },
    data() {
        return {};
    },

    computed: {
        isChecked() {
            if (typeof this.content === 'string') {
                return this.value === this.content;
            }

            return this.value[this.compare] === this.content[this.compare];
        }
    },

    mounted() {}
};
</script>

<style></style>
