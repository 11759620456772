<template>
<!-- Global notification live region, render this permanently at the end of the document -->
<div v-if="totalSelected"
    aria-live="assertive"
    class="notification__wrapper -toolbar"
>
    <div
        class="notification__container"
    >
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition-group
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-for="notification in notifications"
                :key="notification.getId()"
                :class="['notification', `--${notification.getLevel()}`]"
            >
                <div class="notification__body">
                    <div class="notification__bodyWrapper">
                        <div class="notification__iconWrapper">
                            <i :class="getIconClasses(notification)" />
                        </div>
                        <div class="notification__message">
                            <p class="notification__mainMessage">
                                {{ notification.getMessage() }}
                            </p>
                            <p
                                v-show="notification.getSubMessage()"
                                class="notification__subMessage"
                            >
                                {{ notification.getSubMessage() }}
                            </p>
                        </div>
                        <div class="notification__controls">
                            <button
                                class="notification__close"
                                @click.prevent="close(notification)"
                            >
                                <span class="sr-only">Close</span>
                                <i class="far fa-fw fa-times" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</div>
<div v-else
    aria-live="assertive"
    class="notification__wrapper"
>
    <div
        class="notification__container"
    >
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition-group
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-for="notification in notifications"
                :key="notification.getId()"
                :class="['notification', `--${notification.getLevel()}`]"
            >
                <div class="notification__body">
                    <div class="notification__bodyWrapper">
                        <div class="notification__iconWrapper">
                            <i :class="getIconClasses(notification)" />
                        </div>
                        <div class="notification__message">
                            <p class="notification__mainMessage">
                                {{ notification.getMessage() }}
                            </p>
                            <p
                                v-show="notification.getSubMessage()"
                                class="notification__subMessage"
                            >
                                {{ notification.getSubMessage() }}
                            </p>
                        </div>
                        <div class="notification__controls">
                            <button
                                class="notification__close"
                                @click.prevent="close(notification)"
                            >
                                <span class="sr-only">Close</span>
                                <i class="far fa-fw fa-times" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition-group>
    </div>
</div>
</template>

<script>
import NotificationModel from '@/Notifications/NotificationModel';

export default {
    props: {
        notification: String
    },
    data() {
        return {};
    },
    computed: {
        notifications: {
            get() {
                return this.$store.state.notifications;
            },
        },
        totalSelected() {
            return this.$store.state.user.selectedAssets.length;
        },
    },
    methods: {
        close(notification) {
            this.$store.dispatch('clearNotification', notification);
        },
        getIconClasses(notification) {
            let classes = [
                'notification__icon',
                'far',
                'fa-fw',
            ];

            let level = notification.getLevel();

            switch (level) {
            case 'info':
            case 'primary':
            case 'success':
                classes.push('fa-check');
                break;
            case 'danger':
                classes.push('fa-exclamation-triangle');
                break;
            default:
                classes.push('fa-exclamation');
                break;
            }


            return classes.join(' ');
        }
    }
};
</script>

<style>

</style>
