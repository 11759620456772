<template>
    <aside
        :class="[
            'collapsingMenu',
            opened ? getOpenedClasses() : '--closed',
            sticky ? '--sticky' : null,
        ]"
    >
        <div
            class="collapsingMenu__wrapper"
            :class="{
                '--sticky': sticky,
            }"
        >
            <div
                class="collapsingMenu__controls"
                @click.prevent="opened = !opened"
            >
                <span
                    v-if="icon"
                    class="collapsingMenu__icon"
                >
            </span>

                <h3
                    v-if="label && opened"
                    class="collapsingMenu__header"
                    v-text="label"
                />

                <button
                    :class="[
                        'collapsingMenu__toggle',
                        opened ? 'ml-auto' : null,
                    ]"
                >
                    <span
                        :class="[
                            'collapsingMenu__toggleIcon',
                            opened ? '' : '--rotate',
                        ]"
                    ></span>
                </button>
            </div>

            <div
                :class="[
                    'collapsingMenu__content',
                    opened ? '--open' : '--close',
                ]"
            >
                <slot
                    name="content"
                    :isOpen="opened"
                />
            </div>
        </div>
    </aside>
</template>

<script>
export default {
    props: {
        label: {
            required: false,
            type: String,
            default: null,
        },
        remember: {
            required: false,
            type: String,
            default: null,
        },
        icon: {
            required: false,
            type: String,
            default: null,
        },
        closedClasses: {
            required: false,
            type: Array,
            default() {
                return [];
            }
        },
        openedClasses: {
            required: false,
            type: Array,
            default() {
                return [];
            }
        },
        sticky: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: true,
        };
    },
    computed: {
        opened: {
            get() {
                return this.isOpen;
            },
            set(open) {
                this.isOpen = open;
                this.rememberOpen(open);
            }
        }
    },
    mounted() {
        this.opened = this.getRememberOpen();
    },
    methods: {
        rememberOpen(open) {
            if (this.remember) {
                window.localStorage.setItem(this.remember, open);
            }
        },
        getRememberOpen() {
            let open = true;

            if (this.remember) {
                open = window.localStorage.getItem(this.remember);

                if (open === null) {
                    open = true;
                } else {
                    open = (open === 'true');
                }
            }

            return open;
        },
        getOpenedClasses() {
            if (this.openedClasses.length) {
                return this.openedClasses.join(' ');
            }
        },
        getClosedClasses() {
            if (this.openedClasses.length) {
                return this.closedClasses.join(' ');
            }
        }
    }
};
</script>
