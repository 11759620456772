import Vue from "vue"
import BpTabs from "../../../styles/frontend/molecules/tabs/BpTabs.vue"
const tabsSections = [...document.querySelectorAll('.js-tabs')]

if (tabsSections.length){
    tabsSections.forEach(tabsSection => {
        new Vue({
            el: tabsSection,
            components: {BpTabs}
        })
    })
}