<template>
<div class="relatedAssets">
    <div class="relatedAssets--header">
        <h2>
            Related Assets
        </h2>
        <a
            class="link"
            :href="downloadUrl"
        >Download All</a>
    </div>

    <div class="relatedAssets--assets">
        <related-asset
            v-for="asset in assets"
            :key="asset.asset_id"
            :asset="asset"
        />
    </div>
</div>
</template>

<script>
import RelatedAsset from '@/RelatedAsset';
import { DOWNLOAD_ASSETS } from 'Root/endpoints';

export default {
    components: {
        RelatedAsset,
    },
    mixins: [],
    props: {
        assetId: {
            required: true,
            type: Number,
        },
        assets: {
            required: true,
            type: Array,
        }
    },
    data() {
        return {};
    },
    computed: {
        downloadUrl() {
            return `${DOWNLOAD_ASSETS}?all_files=true&related_assets_for=${this.assetId}`;
        }
    }
};
</script>
