<template>
  <section class="searchFilters__filters">
    <div class="searchFilters__filterGroup">
      <h3 class="searchFilters__filterTitle">Sort Results By</h3>

      <div class="searchFilters__radioButtons">
        <radio v-model="order_by" name="order_by" label="Relevance" content="association" @input="search" />

        <radio v-model="order_by" name="order_by" label="Date Created (Newest First)" content="date_created_desc"
          @input="search" />

        <radio v-model="order_by" name="order_by" label="Date Created (Oldest First)" content="date_created_asc"
          @input="search" />

        <radio v-model="order_by" name="order_by" label="Date Updated (Newest First)" content="last_modified_desc"
          @input="search" />

        <radio v-model="order_by" class="mb-4" name="order_by" label="Date Updated (Oldest First)"
          content="last_modified_asc" @input="search" />
      </div>
    </div>

    <div class="searchFilters__filterGroup">
      <h3 class="searchFilters__filterTitle">Locations</h3>
      <toggle-box title="Division" :meta="getSelectedCountText(division_ids)" :open="isOpen(division_ids)">
        <template #content>
          <search-input-checkbox v-model="division_ids" label="Division" label-plural="Divisions" :search="false"
            :show-label="false" :options="divisions" filter-by="name" display-key="name" placeholder="Search for Division"
            @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="State" :meta="getSelectedCountText(states_provinces)" :open="isOpen(states_provinces)">
        <template #content>
          <search-input-checkbox v-model="states_provinces" label="State" label-plural="States" :search="false"
            :show-label="false" :options="states" filter-by="name" display-key="name" placeholder="Search for State"
            @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="City" :meta="getSelectedCountText(cities)" :open="isOpen(cities)">
        <template #content>
          <search-input-text id="city" v-model="cities" label="City" :show-label="false" placeholder="Enter city"
            @input="search" />
        </template>
      </toggle-box>
    </div>

    <div class="searchFilters__filterGroup">
      <h3 class="searchFilters__filterTitle">Collections & Groups</h3>

      <toggle-box title="My Groups" :meta="getSelectedCountText(group_ids)" :open="isOpen(group_ids)">
        <template #content>
          <search-input-checkbox v-model="group_ids" label="Group" label-plural="Groups" :search="false"
              :show-label="false" :options="isAdmin ? adminGroups : groups" filter-by="user_group_id" display-key="name"
              placeholder="Search for Group" @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="Photography Subject" :meta="getSelectedCountText(subject_ids)" :open="isOpen(subject_ids)">
        <template #content>
          <search-input-checkbox v-model="subject_ids" label="Subject" label-plural="Subjects" :search="false"
            :show-label="false" :options="subjects" filter-by="subject_id" display-key="name"
            placeholder="Search for Subject" @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="Collections" :meta="getSelectedCountText(collection_ids)" :open="isOpen(collection_ids)">
        <template #content>
          <search-input-checkbox v-model="collection_ids" label="Collection" label-plural="Collections" :search="false"
            :show-label="false" :options="collections" filter-by="collection_id" display-key="name"
            placeholder="Search for Collection" @input="search" />
        </template>
      </toggle-box>
    </div>

    <div class="searchFilters__filterGroup">
      <toggle-box title="Date Created" :open="date_created_start !== null || date_created_end !== null">
        <template #content>
          <span class="bold">From</span>

          <date-picker v-model="date_created_start" name="date_created_start" format="yyyy-MM-dd"
            @input="updateDateField('date_created_start', $event)" />

          <span class="bold">To</span>
          <date-picker v-model="date_created_end" name="date_created_end" format="yyyy-MM-dd"
            @input="updateDateField('date_created_end', $event)" />
        </template>
      </toggle-box>
      <toggle-box title="Date Updated" :open="last_modified_start !== null || last_modified_end !== null
        ">
        <template #content>
          <span class="bold">From</span>
          <date-picker v-model="last_modified_start" name="date_created_end" format="yyyy-MM-dd"
            @input="updateDateField('last_modified_start', $event)" />

          <span class="bold">To</span>
          <date-picker v-model="last_modified_end" name="date_created_end" format="yyyy-MM-dd"
            @input="updateDateField('last_modified_end', $event)" />
        </template>
      </toggle-box>
    </div>

    <div class="searchFilters__filterGroup">
      <h3 class="searchFilters__filterTitle">Asset Details</h3>

      <div class="searchFilters__radioButtons -toggles">
        <toggle-switch id="brand_only" v-model="brand_only" name="brand_only" input-value="true" text="Brand Only"
          @input="search" />

        <toggle-switch id="property_only" v-model="property_only" name="property_only" input-value="true"
          text="Property Only" @input="search" />

        <toggle-switch id="has_rights_documents" v-model="has_rights_documents" name="has_rights_documents"
          input-value="true" text="Has Rights Documents" @input="search" />
      </div>

      <toggle-box title="Template Type" :meta="getSelectedCountText(template_types)" :open="isOpen(template_types)">
        <template #content>
          <search-input-checkbox v-model="template_types" label="Template Types" label-plural="Template Types"
            :search="false" :show-label="false" :options="templateTypes" filter-by="name" display-key="name"
            @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="Colors" :meta="getSelectedCountText(selected_colors)" :open="isOpen(selected_colors)">
        <template #content>
          <search-input-checkbox v-model="selected_colors" label="Color" label-plural="Colors" :search="false"
            :show-label="false" :options="colors" filter-by="name" display-key="name" @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="Destination" :meta="getSelectedCountText(destination_controls)"
        :open="isOpen(destination_controls)">
        <template #content>
          <search-input-checkbox v-model="destination_controls" label="Destination" label-plural="Destinations"
            :search="false" :show-label="false" :options="destinations" filter-by="id" display-key="name"
            @input="search" />
        </template>
      </toggle-box>

      <toggle-box title="External" :open="is_stock || from_mdam || hidden_from_search || from_dac">
        <template #content>
          <div class="searchFilters__radioButtons">
            <toggle-switch id="is_stock" v-model="is_stock" name="is_stock" :input-value="true" text="Is Stock"
              @input="search" />

            <toggle-switch v-if="isSuperAdmin" id="from_mdam" v-model="from_mdam" name="from_mdam" :input-value="true"
              text="Imported From MDAM" @input="search" />

            <toggle-switch v-if="isSuperAdmin" id="from_dac" v-model="from_dac" name="from_dac" :input-value="true"
              text="Imported From DAC" @input="search" />

            <toggle-switch v-if="isSuperAdmin" id="hidden_from_search" v-model="hidden_from_search"
              name="hidden_from_search" :input-value="true" text="Hidden from Search" @input="search" />
          </div>
        </template>
      </toggle-box>
    </div>

    <div class="searchFilters__filterGroup">
      <h3 class="searchFilters__filterTitle">Asset Status</h3>

      <div class="searchFilters__radioButtons">
        <div v-for="stat in statuses" :key="stat.id" class="searchFilters__radioButtonWrapper">
          <radio v-model="status" :name="stat.name" :label="stat.name" :content="stat" compare="value"
            placeholder="Search for Asset Status" @input="search" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Radio from '@/Radio';
import ToggleBox from '@/ToggleBox';
import SearchInputCheckbox from '@/SearchInputCheckbox';
import SearchInputText from '@/SearchInputText';
import DatePicker from '@/DatePicker';
import ToggleSwitch from '@/ToggleSwitch';
import mutations from 'Stores/Frontend/mutation-types';
import { find } from 'lodash';

export default {
  components: {
    Radio,
    ToggleBox,
    ToggleSwitch,
    SearchInputCheckbox,
    SearchInputText,
    DatePicker,
  },
  props: {
    divisions: {
      required: true,
      default: () => [],
      type: Array
    },
    states: {
      required: true,
      default: () => [],
      type: Array
    },
    countries: {
      required: true,
      default: () => [],
      type: Array
    },
    destinations: {
      required: true,
      default: () => [],
      type: Array
    },
    groups: {
      required: true,
      default: () => [],
      type: Array
    },
    adminGroups: {
      required: true,
      default: () => [],
      type: Array
    },
    subjects: {
      required: true,
      default: () => [],
      type: Array
    },
    collections: {
      required: true,
      default: () => [],
      type: Array
    },
    statuses: {
      required: true,
      default: () => [],
      type: Array
    },
    templateTypes: {
      required: true,
      default: () => [],
      type: Array
    },
    templateTypeCategories: {
      required: true,
      default: () => [],
      type: Array
    },
    colors: {
      required: true,
      default: () => [],
      type: Array
    },
    fileTypes: {
      required: true,
      default: () => [],
      type: Array
    },
    isAdmin: {
      required: false,
      default: false,
      type: Boolean
    },
    isSuperAdmin: {
      required: false,
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      invalidDateFields: {}
    };
  },
  computed: {
    query() {
      const { query } = this.$store.state.search;

      if (query) {
        return query;
      }

      return {};
    },
    order_by: {
      get() {
        return this.query.order_by;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_ORDER_BY, value);
      }
    },
    division_ids: {
      get() {
        return this.query.divisions;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_DIVISIONS, value);
      }
    },
    states_provinces: {
      get() {
        return this.query.states;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_STATES, value);
      }
    },
    cities: {
      get() {
        return this.query.cities;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_CITIES, value);
      }
    },
    group_ids: {
      get() {
        return this.query.groups;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_GROUPS, value);
      }
    },
    subject_ids: {
      get() {
        return this.query.subjects;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_SUBJECTS, value);
      }
    },
    collection_ids: {
      get() {
        return this.query.collections;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_COLLECTIONS, value);
      }
    },
    date_created_start: {
      get() {
        return this.query.date_created_start;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_DATE_CREATED_START,
          value
        );
      }
    },
    date_created_end: {
      get() {
        return this.query.date_created_end;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_DATE_CREATED_END,
          value
        );
      }
    },
    last_modified_start: {
      get() {
        return this.query.last_modified_start;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_LAST_MODIFIED_START,
          value
        );
      }
    },
    last_modified_end: {
      get() {
        return this.query.last_modified_end;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_LAST_MODIFIED_END,
          value
        );
      }
    },
    from_mdam: {
      get() {
        return this.query.from_mdam;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_FROM_MDAM, value);
      }
    },
    hidden_from_search: {
      get() {
        return this.query.hidden_from_search;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_HIDDEN_FROM_SEARCH,
          value
        );
      }
    },
    destination_controls: {
      get() {
        return this.query.destinations;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_DESTINATIONS, value);
      }
    },
    is_stock: {
      get() {
        return this.query.is_stock;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_IS_STOCK, value);
      }
    },
    status: {
      get() {
        return this.query.status;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_STATUS, value);
      }
    },
    asset_ids: {
      get() {
        return this.query.assets;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_ASSET_IDS);
      }
    },
    brand_only: {
      get() {
        return this.query.brand_only;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_BRAND_ONLY, value);
      }
    },
    property_only: {
      get() {
        return this.query.property_only;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_PROPERTY_ONLY, value);
      }
    },
    has_rights_documents: {
      get() {
        return this.query.has_rights_documents;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_HAS_RIGHTS_DOCUMENTS, value);
      }
    },
    template_types: {
      get() {
        return this.query.template_types;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_TEMPLATE_TYPES, value);
      }
    },
    template_type_categories: {
      get() {
        return this.query.template_type_categories;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_TEMPLATE_TYPE_CATEGORIES,
          value
        );
      }
    },
    selected_colors: {
      get() {
        return this.query.colors;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_COLORS, value);
      }
    },
    file_types: {
      get() {
        return this.query.file_types;
      },
      set(value) {
        this.$store.commit(mutations.SET_SEARCH_FILE_TYPES, value);
      }
    },
    from_dac: {
      get() {
        return this.query.from_dac;
      },
      set(value) {
        this.$store.commit(
          mutations.SET_SEARCH_FROM_DAC,
          value
        );
      }
    },
    today() {
      return new Date().toLocaleDateString('en-CA');
    }
  },
  mounted() {
    if (window.loggedInUser) {
      this.$store.commit('user/SET_USER', JSON.parse(window.loggedInUser));
    }

    this.$store.commit(mutations.SET_SEARCH_STATUS, this.getDefaultStatus());
  },
  methods: {
    getDefaultStatus() {

      let user = this.$store.state.user.user;

      if (Object.keys(user).length > 0) {
        if (user.is_admin || user.is_super_admin) {
          return find(this.statuses, { value: 'all' });
        }

        if (find(user.permissions.data, { name: 'view_revisions_needed' })) {
          return find(this.statuses, { value: 'active_and_revisions_needed' });
        }
      }

      return find(this.statuses, { value: 'active' });

    },
    getSelectedCountText(array) {
      if (Array.isArray(array)) {
        return array.length > 0 ? `${array.length} selected` : '';
      }

      return '';
    },
    isOpen(value) {
      if (value && value.length > 0) {
        return true;
      }

      return false;
    },
    isValidDate(field) {
      if (this.invalidDateFields[field]) {
        return true;
      }

      return false;
    },
    updateDateField(field, value) {
      this.search();
    },
    search() {
      this.$store.dispatch('search');
    }
  }
};
</script>

<style></style>
