import Vue from "vue"
import BpDropdown from "@/Dropdown/BpDropdown"
const dropdownSections = [...document.querySelectorAll('.js-dropdown')]

if (dropdownSections.length){
    dropdownSections.forEach(dropdownSection => {
        new Vue({
            el: dropdownSection,
            components: {BpDropdown}
        })
    })
}
